import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Typography from '@components/atoms/typography'
import LoadingCascadeLogo from '@components/loading-logo'
import { ACCESS_TOKEN_STORAGE_KEY, FAV_COMPANY } from '@constants/app'
import { AUTH_TOS_TIMESTAMP } from '@constants/config'
import AuthContext from '@contexts/auth'
import { setMetadata } from '@contexts/auth'
import Company from '@interfaces/company'
import { LayoutFilter } from '@interfaces/filter'
import { Alert } from '@material-tailwind/react'
import AnalyticsWarmerService from '@services/api-analytics/cache-warmer'
import {
  fetchAnalyticsHistoricals,
  HistoricalAnalyticsType,
} from '@services/api-analytics/historical-loan-dates'
import ExchangeService from '@services/api-analytics/risk-currency-exchange'
import CommonService from '@services/api-common/company'
import ManageWarmerService from '@services/api-manage/cache-warmer'
import {
  fetchManageHistoricals,
  HistoricalManageType,
} from '@services/api-manage/historical-banking-dates'

import Sidebar from './sidebar'
import Sidemenu from './sidemenu'
import SidebarFooter from './sidemenu-footer'

import 'react-toastify/dist/ReactToastify.css'

const AuthLayout = () => {
  const {
    userMetadata,
    setUserMetadata,
    company: companyDetails,
    setCompanyDetails,
    setOptionFilters,
    setActiveFilters,
    setAppliedFilters,
    setShowFilter,
    showSidemenu,
    setShowSidemenu,
  } = useContext(AuthContext)
  const [isTokenSet, setIsTokenSet] = useState(false)
  const [loadingCompany, setLoadingCompany] = useState(true)
  const [pendoInitialized, setPendoInitialized] = useState(false)
  const [getCompanyError, setGetCompanyError] = useState('')
  const [getAuthResponse, setAuthResponse] = useState('')
  const [companyList, setCompanyList] = useState<Company[]>([])

  const [expandMenu, setExpandMenu] = useState<boolean>(false)

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0()
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const provider: string | undefined = user?.sub?.split('|')?.[0]

  const {
    data: companiesResponse,
    isLoading: isCompaniesLoading,
    isError: isCompaniesInError,
  } = useQuery(
    ['user'],
    () => {
      setLoadingCompany(true)
      return CommonService.getCompanies()
    },
    {
      enabled: user != null && isTokenSet,
      retry: failureCount => {
        if (failureCount < 8) {
          // the getCompanies call will timeout at 15s - we retry for ~2min.
          return true
        }
        return false
      },
      // We don't want an exponential back-off here - we're retrying
      // to make up for a sluggish lambda & databricks start
      retryDelay: 1000,
    }
  )

  useEffect(() => {
    if (!isCompaniesLoading && companiesResponse) {
      try {
        const companyResult = companiesResponse.data.sort((a, b) => {
          return a.currencies_available?.length === 0
            ? 1
            : a.legal_name > b.legal_name
            ? 1
            : -1
        })

        const favCompany = localStorage.getItem(FAV_COMPANY)
        setCompanyList(companyResult)
        !!searchParams.get('pid')
          ? changeCompany(
              companyResult.filter(
                p => p.slug_name == searchParams.get('pid')
              )[0]
            )
          : !!favCompany
          ? changeCompany(
              companyResult.filter(p => p.slug_name == favCompany)[0]
            )
          : changeCompany(companyResult?.[0])
      } catch (error) {
        setGetCompanyError('There was an error obtaining your company details.')
      } finally {
        setLoadingCompany(false)
      }
    } else if (isCompaniesInError) {
      setGetCompanyError('There was an error obtaining your company details.')
      setLoadingCompany(false)
    }
  }, [companiesResponse, isCompaniesLoading, isCompaniesInError])

  useEffect(() => {
    if (!user || !isTokenSet) {
      return
    }

    const companies = userMetadata?.companies
    const company_slugs: string | undefined = companies
      ? Object.keys(companies).join(',')
      : undefined
    const is_superadmin = userMetadata?.isSuperadmin
    const TOSAccepted: boolean | undefined = userMetadata?.tosAccepted
    const TOSTimestamp: number | undefined = userMetadata?.tosTimestamp

    if (!company_slugs && !is_superadmin) {
      if (provider === 'auth0') {
        setLoadingCompany(false)
        setGetCompanyError(
          'Your account is not associated with any company. Please contact your administrator.'
        )
        return
      } else {
        navigate('/auth-handler')
        return
      }
    }

    if (
      !TOSAccepted ||
      !TOSTimestamp ||
      moment(TOSTimestamp).unix() < Number(AUTH_TOS_TIMESTAMP)
    ) {
      navigate('/auth-callback')
      return
    }

    if (user && companyDetails && !pendoInitialized) {
      // https://support.pendo.io/hc/en-us/articles/360031862272-Install-Pendo-on-a-single-page-web-application
      // Initialize is for on first load to set up visitor object for tracking
      // Can be anonyomous if id passed in is 'VISITOR-UNIQUE-ID'
      pendo.initialize({
        visitor: {
          id: user.sub ?? '',
          name: user.name ?? '',
          email: user.email ?? '',
        },
        account: {
          id: companyDetails.slug_name,
        },
      })
      setPendoInitialized(true)
    }
  }, [user, userMetadata, isTokenSet, companyDetails])

  // If parameters in the URL contain data encoding either a file or a
  // reference to specific page (as used for advance requests or doc downloads)
  const hasPathInParams = (url: string): boolean => {
    const urlObj = new URL(url)
    const searchParams = urlObj.searchParams
    return (
      searchParams.has('tab') &&
      (searchParams.get('tab') == 'document-centre' ||
        searchParams.get('tab') == 'advance-request')
    )
  }

  const navigateToPathFromParams = (urlParam: string, value: string) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(urlParam, value)
    navigate(`?${searchParams.toString()}`)
  }

  /**
   * @param pid
   * @param value
   * Because we have different authentication and settings for different users, we need use a default url to handle the differences until we have better backtrakcing mechanism
   */
  const navigateToHomePath = (urlParam: string, value: string) => {
    navigate(`/?${urlParam}=${value}`)
  }
  /**
   * For setting company details on SYNCHRONOUS tasks, ensures our UI changes right away in sidemenu and other critical parts
   * @param c
   */
  const applyDetailsToCompany = (c: Company) => {
    const currentUrl = window.location.href
    if (hasPathInParams(currentUrl)) {
      navigateToPathFromParams('pid', c.slug_name)
    } else {
      navigateToHomePath('pid', c.slug_name) // greedy home path to Analytics/Risk
    }
    setCompanyDetails({
      ...c,
      date_end: moment(c.date_end).isAfter(moment())
        ? moment().format('YYYY-MM-DD')
        : c.date_end,
      currencies_available: c.currencies_available?.map(x => ({
        from_currency: ExchangeService.getLatestISOCode(x?.from_currency ?? ''),
        to_currency: ExchangeService.getLatestISOCode(x?.to_currency ?? ''),
      })),
    })
    setOptionFilters({})
    setAppliedFilters({})
    setActiveFilters({})
    setShowFilter(false) // close sidemenu at end of operation
  }

  /**
   * For setting company details on ASYNCHRONOUS tasks, set after P1 is success to revent race conditions
   * @param c
   */
  const applyDetailsToCompanyAsync = async (c: Company) => {
    // Theses calls are resolved at later time beacuse the async delay's our greedy swap
    const [analyticsHistoricalsObj, manageHistoricalsObj]: [
      HistoricalAnalyticsType,
      HistoricalManageType
    ] = await Promise.all([
      fetchAnalyticsHistoricals(c.slug_name),
      fetchManageHistoricals(c.slug_name),
    ])
    setCompanyDetails({
      ...c,
      loanTapeAsOf: analyticsHistoricalsObj.loanTapeAsOf,
      lastAnalyticsPipeline: analyticsHistoricalsObj.lastPipeline,
      financialsAsOf: analyticsHistoricalsObj.financialsAsOf,
      lastFinancialsPipeline: analyticsHistoricalsObj.lastFinancialsPipeline,
      bankDataAsOf: manageHistoricalsObj.bankDataAsOf,
      lastManagePipeline: manageHistoricalsObj.lastPipeline,
    })
  }

  const changeCompany = async (c: Company) => {
    // Not waiting on response - intentionally a background task!
    if (c.has_analytics) {
      AnalyticsWarmerService.warmup({ slug_name: c.slug_name })
    }
    if (c.has_manage) {
      ManageWarmerService.warmup({ slug_name: c.slug_name })
    }

    const currentUrl = window.location.href
    if (hasPathInParams(currentUrl)) {
      navigateToPathFromParams('pid', c.slug_name)
    } else {
      // greedy home path to Analytics/Risk
      navigateToHomePath('pid', c.slug_name)
    }

    if (!loadingCompany) {
      // https://agent.pendo.io/events/pendo-events
      // Identify is on updating client details (e.g., switching companies) after initialisation.
      // Track keeps track of specific events we want to monitor.
      pendo.identify({
        visitor: {
          id: user?.sub ?? '',
        },
        account: {
          id: c.slug_name,
        },
      })
      pendo.track('Switch Company')
    }

    applyDetailsToCompany(c)
    applyDetailsToCompanyAsync(c)
    setShowSidemenu(true)
  }

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: { returnTo: location.pathname + location.search },
      })
    }
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    getAccessTokenSilently().then(token => {
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token)
      if (!userMetadata) {
        setMetadata(user, setUserMetadata, setAuthResponse)
        return
      }
      setIsTokenSet(true)
    })
  }, [isAuthenticated, userMetadata])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const searchSlugname = params.get('pid')
    if (
      searchSlugname &&
      companyDetails &&
      searchSlugname.length > 0 &&
      companyDetails?.slug_name !== searchSlugname
    ) {
      const company = companyList.find(c => c.slug_name === searchSlugname)
      if (company) {
        changeCompany(company)
        const searchFacilityID = params.get('facilityID')
        if (searchFacilityID) {
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            activeFacilityId: searchFacilityID
              ? parseInt(searchFacilityID)
              : undefined,
          }))
          setAppliedFilters((prev: LayoutFilter) => ({
            ...prev,
            activeFacilityId: searchFacilityID
              ? parseInt(searchFacilityID)
              : undefined,
          }))
        }
        navigate(`?${params.toString()}`)
      } else {
        setGetCompanyError(
          `Your account does not have access to the requested facility. Please contact your administrator.`
        )
        return
      }
    } else {
      companyDetails &&
        navigateToPathFromParams('pid', companyDetails?.slug_name)
    }
  }, [pathname])

  if (!isLoading && !isTokenSet && getAuthResponse.length > 0) {
    return (
      <div className="flex flex-col h-[calc(100dvh)] px-[25%] bg-primary-surface-2 justify-center items-center">
        <Alert className="bg-danger-main text-center">{getAuthResponse}</Alert>
      </div>
    )
  }

  if (isLoading || !isTokenSet || loadingCompany) {
    return (
      <div className="flex flex-col w-screen h-[calc(100dvh)] bg-primary-surface-2 justify-center items-center">
        <LoadingCascadeLogo />
        <Typography className="font-sm text-center text-neutral-body-2 group-hover:text-neutral-white">
          We are getting things ready...
        </Typography>
      </div>
    )
  }

  if (getCompanyError.length > 0) {
    return (
      <div className="flex flex-col h-[calc(100dvh)] px-[25%] bg-primary-surface-2 justify-center items-center">
        <Alert className="bg-danger-main text-center">{getCompanyError}</Alert>
      </div>
    )
  }

  if (pathname === '/404') {
    return <Outlet />
  }

  return (
    <div className="bg-primary-surface-2 flex flex-col navthreshold:flex-row h-screen w-screen">
      {/* navmenu */}
      <div
        className={`
          ${
            expandMenu ? 'h-full md:h-menu' : 'h-sidebar'
          } lg:h-screen flex flex-col bg-neutral-white z-[999] 

          ${showSidemenu ? 'lg:w-side' : 'lg:w-sidebar'} 
          
          lg:[box-shadow:0px_0px_10px_rgba(0,0,0,0.1)] 
        `}
      >
        <div
          onClick={() => setExpandMenu(!expandMenu)}
          className={`absolute w-screen h-[calc(100dvh)] bg-[rgba(0,0,0,.3)] top-0 left-0 z-0 blur-10 ${
            expandMenu ? 'block' : 'hidden'
          } lg:hidden`}
        />
        <div className="flex flex-1 h-full">
          <Sidebar expanded={expandMenu} />
          <Sidemenu
            show={showSidemenu}
            onShow={() => setShowSidemenu(!showSidemenu)}
            expanded={expandMenu}
            onExpand={() => setExpandMenu(!expandMenu)}
          />
        </div>
        <SidebarFooter
          expanded={showSidemenu}
          companies={companyList}
          onSelectCompany={changeCompany}
        />
      </div>

      <div className={`flex-1 overflow-auto `}>
        <Outlet />
      </div>
      <ToastContainer />
    </div>
  )
}

export default AuthLayout
