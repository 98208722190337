import React, { useEffect, useState } from 'react'

const NewFeatureIcon = ({
  localTag,
  className = '',
}: {
  localTag: string
  className?: string
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  useEffect(() => {
    const hasSeenTag = localStorage.getItem(localTag)
    if (!hasSeenTag) {
      setVisible(true)
    }
  }, [localTag])

  return (
    <div className={`absolute -top-3 right-7 ${className} z-9999 `}>
      {visible && (
        <div
          className={`absolute px-2 py-[2px] bg-teal rounded-full text-[11px] font-medium text-white z-[11]`}
        >
          NEW
        </div>
      )}
      {visible && (
        <div
          className={`pulse-layer-1 px-2 py-[2px] bg-teal rounded-full text-[11px] font-medium text-white `}
        >
          NEW
        </div>
      )}
    </div>
  )
}

export default NewFeatureIcon
