import React, { useState } from 'react'

import Typography from '@components/atoms/typography'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { Tooltip } from '@material-tailwind/react'

interface MultiLevelTableProps {
  defaultCurrency: string
  dataSet: any
  currency: string
  showTotals?: boolean
  itemsTitle?: string
  headerText?: string
  hasCustomStyling?: boolean
}
interface Info {
  defaultCurrency: string
  item: string
  info: any
  values: number[]
  footnote?: string
  style?: string
}
const MultiLevelTable = ({
  defaultCurrency,
  dataSet,
  currency,
  showTotals,
  itemsTitle,
  headerText,
  hasCustomStyling = false,
}: MultiLevelTableProps) => {
  const [expanded, setExpanded] = useState<string[]>([])
  const [hover, setHover] = useState<string | undefined>()

  const onExpand = (item: string) => {
    const items = expanded.includes(item)
      ? expanded.filter(i => i !== item)
      : [...expanded, item]
    setExpanded(items)
  }

  const zoneNumbers = (
    includeCurrency: boolean,
    num: number,
    isPercent?: boolean
  ) => {
    if (num === null) {
      return ''
    }
    const formatOptions: Intl.NumberFormatOptions = {
      style: includeCurrency ? 'currency' : 'decimal',
      currency: currency,
      currencyDisplay: 'code',
      currencySign: 'accounting',
    }

    if (!isPercent) {
      // a value of 10.5 gets rounded up; a value of 10.4 rounded down:
      formatOptions.roundingMode = 'halfCeil'
      formatOptions.minimumFractionDigits = 0
      formatOptions.maximumFractionDigits = 0
    } else {
      formatOptions.minimumFractionDigits = 2
      formatOptions.maximumFractionDigits = 2
    }

    const formattedValue = Intl.NumberFormat('en-US', formatOptions).format(num)
    const cleanedValue = formattedValue.replace(currency, '').trim()
    let formattedString = ''

    if (includeCurrency) {
      formattedString = `${currency} 1 - ${defaultCurrency} ${cleanedValue}`
    } else {
      formattedString = cleanedValue
    }

    if (isPercent) {
      formattedString += '%'
    }

    return formattedString
  }

  return (
    <div className="w-full overflow-x-auto rounded-lg">
      {headerText}
      <table className="w-full">
        <thead>
          <tr>
            <th
              className={`whitespace-nowrap sticky top-0 left-0 ${
                hasCustomStyling ? 'bg-primary-surface-2' : 'bg-white'
              } text-sm text-neutral-subtitle-3 font-bold text-end p-2 w-fit z-[3]`}
            >
              {itemsTitle}
            </th>
            {dataSet.cols?.map((date: any, i: number) => (
              <th
                key={i}
                className={`whitespace-nowrap sticky top-0 ${
                  hasCustomStyling ? 'bg-primary-surface-2' : 'bg-white'
                } text-sm font-bold text-end p-2 w-fit`}
              >
                {date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSet.data?.map((di: any, i: number) => {
            const isExpanded = expanded.includes(di.item)
            const Icon = isExpanded ? ChevronDownIcon : ChevronRightIcon
            const expandable = !!di.info?.length
            return (
              <>
                <tr
                  key={i}
                  className={`text-sm ${
                    hasCustomStyling && di.style?.includes('line-below')
                      ? 'border-b-8 border-double'
                      : 'border-b'
                  } border-neutral-border-1 ${
                    !expandable && 'border-b border-neutral-border-1'
                  } ${
                    (hasCustomStyling ? di.style?.includes('bold') : true) &&
                    'font-bold'
                  } ${
                    (hasCustomStyling
                      ? di.style?.includes('uppercase')
                      : true) && 'uppercase'
                  }`}
                  onMouseEnter={() => setHover(di.item)}
                  onMouseLeave={() => setHover('')}
                >
                  <td
                    className={`${
                      hover === di.item && 'bg-primary-surface-2 z-[1]'
                    }  border-r-2 ${
                      hasCustomStyling ? 'bg-primary-surface-2' : 'bg-white'
                    } sticky left-0 flex cursor-pointer ${
                      !hasCustomStyling && 'font-bold'
                    } ${!expandable ? 'pl-10 py-2' : 'p-2'}`}
                    onClick={() => expandable && onExpand(di.item)}
                  >
                    {!!di.info?.length && <Icon className="w-4 mx-2" />}
                    {di.item}
                    {di.footnote && (
                      <div className="flex justify-middle mx-1">
                        <Tooltip
                          content={
                            <Typography className="text-xs font-light text-left pl-1">
                              {di.footnote}
                            </Typography>
                          }
                          placement="right"
                        >
                          <QuestionMarkCircleIcon className="w-3 ml-1 text-cc-icon-primary cursor-help hover:text-primary-hover" />
                        </Tooltip>
                      </div>
                    )}
                  </td>
                  {di.values.map((mi: number, j: number) => (
                    <td
                      key={j}
                      className={`${
                        hover === di.item && 'bg-primary-surface-2 z-[1]'
                      } text-sm ${
                        hasCustomStyling &&
                        di.style?.includes('bold') &&
                        'font-bold'
                      } text-end p-2 ${isExpanded && showTotals && 'hidden'}`}
                    >
                      {zoneNumbers(
                        di.path === 'exchange_rate',
                        mi,
                        di.style?.includes('percentage')
                      )}
                    </td>
                  ))}
                </tr>
                {expanded.includes(di.item) &&
                  di.info?.map((p: any, x: number) => {
                    const isExpanded =
                      expanded.includes(p.item) && p.info?.length
                    const Icon = isExpanded ? ChevronDownIcon : ChevronRightIcon
                    const expandable = !!p.info?.length
                    return (
                      <>
                        <tr
                          key={x}
                          className={`bg-neutral-white ${
                            hasCustomStyling && p.style?.includes('line-below')
                              ? 'border-b-8 border-double'
                              : 'border-b'
                          } border-neutral-border-1 ${
                            (hasCustomStyling
                              ? p.style?.includes('bold')
                              : true) && 'font-bold'
                          }
`}
                          onMouseEnter={() => setHover(p.item)}
                          onMouseLeave={() => setHover('')}
                        >
                          <td
                            className={`${
                              hover === p.item && 'bg-primary-surface-2 z-[1]'
                            } border-r-2 ${
                              hasCustomStyling
                                ? 'bg-primary-surface-2'
                                : 'bg-white'
                            } sticky left-0 flex flex-row text-start py-2 ${
                              expandable
                                ? 'pl-8 cursor-pointer font-bold text-sm'
                                : 'pl-14 font-medium text-xs'
                            }`}
                            onClick={() => !!p.info?.length && onExpand(p.item)}
                          >
                            {expandable && <Icon className="w-4 mx-2" />}
                            <div>{p.item}</div>
                            <div className="flex justify-middle mx-1">
                              {p.footnote && (
                                <Tooltip
                                  content={
                                    <Typography className="text-xs font-light text-left pl-1">
                                      {p.footnote}
                                    </Typography>
                                  }
                                  placement="right"
                                >
                                  <QuestionMarkCircleIcon className="w-3 ml-1 text-cc-icon-primary cursor-help hover:text-primary-hover" />
                                </Tooltip>
                              )}
                            </div>
                          </td>
                          {p.values.map((pi: number, i: number) => (
                            <td
                              key={i}
                              className={`${
                                hover === p.item && 'bg-primary-surface-2 z-[1]'
                              } text-xs text-end p-2 font-medium ${
                                isExpanded && showTotals && 'hidden'
                              } ${expandable && 'text-sm font-bold'}`}
                            >
                              {zoneNumbers(
                                false,
                                pi,
                                p.style?.includes('percentage')
                              )}
                            </td>
                          ))}
                        </tr>
                        {!!p.info?.length &&
                          expanded.includes(p.item) &&
                          p.info.map((pi: Info, i: number) => {
                            return (
                              <tr
                                key={i}
                                className={`${
                                  hasCustomStyling &&
                                  pi.style?.includes('line-below')
                                    ? 'border-b-8 border-double'
                                    : 'border-b'
                                } border-neutral-border-1 ${
                                  (hasCustomStyling
                                    ? di.style?.includes('bold')
                                    : true) && 'font-bold'
                                }
`}
                                onMouseEnter={() => setHover(pi.item)}
                                onMouseLeave={() => setHover('')}
                              >
                                <td
                                  className={`${
                                    hover === pi.item &&
                                    !expanded.includes(pi.item) &&
                                    'bg-primary-surface-2 z-[1]'
                                  } pr-4 border-r-2 ${
                                    hasCustomStyling
                                      ? 'bg-primary-surface-2'
                                      : 'bg-white'
                                  } sticky left-0 flex text-xs text-start pl-20 py-2 font-medium`}
                                >
                                  <div>{pi.item}</div>
                                  {pi.footnote && (
                                    <div className="flex justify-middle mx-1">
                                      <Tooltip
                                        content={
                                          <Typography className="text-xs font-light text-left pl-1">
                                            {pi.footnote}
                                          </Typography>
                                        }
                                        placement="right"
                                      >
                                        <QuestionMarkCircleIcon className="w-3 ml-1 text-cc-icon-primary cursor-help hover:text-primary-hover" />
                                      </Tooltip>
                                    </div>
                                  )}
                                </td>
                                {pi.values.map((ni: number, i: number) => (
                                  <td
                                    key={i}
                                    className={`${
                                      hover === pi.item &&
                                      'bg-primary-surface-2 z-[1]'
                                    } text-xs text-end p-2 font-medium`}
                                  >
                                    {zoneNumbers(
                                      false,
                                      ni,
                                      pi.style?.includes('percentage')
                                    )}
                                  </td>
                                ))}
                              </tr>
                            )
                          })}
                        {showTotals &&
                          !!p.info?.length &&
                          expanded.includes(p.item) && (
                            <tr
                              className={`border-y border-neutral-border-2`}
                              onMouseEnter={() => setHover('t' + p.item)}
                              onMouseLeave={() => setHover('')}
                            >
                              <td
                                className={`${
                                  hover === 't' + p.item &&
                                  'bg-primary-surface-2 z-[1]'
                                }
                                   border-r-2 bg-white sticky left-0 flex pl-16 py-2 text-sm font-bold`}
                              >
                                {`Total ${p.item}`}
                              </td>
                              {p.values.map((li: number, i: number) => (
                                <td
                                  key={i}
                                  className={`${
                                    hover === 't' + p.item &&
                                    'bg-primary-surface-2 z-[1]'
                                  } text-sm text-end font-bold p-2`}
                                >
                                  {zoneNumbers(
                                    false,
                                    li,
                                    p.style?.includes('percentage')
                                  )}
                                </td>
                              ))}
                            </tr>
                          )}
                      </>
                    )
                  })}
                {showTotals && expanded.includes(di.item) && (
                  <tr
                    className="border-y border-neutral-border-3"
                    onMouseEnter={() => setHover('t' + di.item)}
                    onMouseLeave={() => setHover('')}
                  >
                    <td
                      className={`${
                        hover === 't' + di.item && 'bg-primary-surface-2 z-[1]'
                      } py-2 flex border-r-2 bg-white sticky left-0 pl-10 text-sm font-bold`}
                    >
                      {`total ${di.item}`.toUpperCase()}
                    </td>
                    {di.values.map((ti: number, i: number) => (
                      <td
                        key={i}
                        className={`${
                          hover === 't' + di.item &&
                          'bg-primary-surface-2 z-[1]'
                        } text-sm text-end font-bold p-2`}
                      >
                        {zoneNumbers(
                          false,
                          ti,
                          di.style?.includes('percentage')
                        )}
                      </td>
                    ))}
                  </tr>
                )}
              </>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
export default MultiLevelTable
