import React, { FC, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import AuthContext from '@contexts/auth'
import {
  Card,
  CardBody,
  Option,
  Select,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from '@material-tailwind/react'

import BaseLayout, { BaseLayoutProps } from './base-layout'
import { useActiveTab } from './tabs-context'
interface L3BaseLayoutProps extends BaseLayoutProps {
  /**
   * L4 tabs for this L3
   */
  tabs: {
    /**
     * Tab label
     */
    label: string
    /**
     * Tab body component
     */
    component: JSX.Element
    /**
     * Classname that will applied to
     */
    headerClassName?: string
    /**
     * Id that will be used to identify the tab, if not present
     * will fall back to using label
     */
    id?: string
  }[]
  /**
   * fallback when tabs is empty array
   */
  empty?: React.ReactNode
  /**
   * wheter switchable to dropdown or not
   */
  switchable?: boolean
}
/**
 * Base layout for L3 that use tab as main layout of its L4s
 * @param {L3BaseLayoutProps} props Component properties
 * @returns JSX
 */
const L3TabsLayout: FC<L3BaseLayoutProps> = ({
  tabs,
  filters,
  title,
  disableReset = true,
  empty,
  switchable = false,
  rightFooterContent,
}) => {
  const { company } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get('tab')
  const tabIndex = tabs.findIndex(t =>
    t.id ? t.id === tab : t.label.toLowerCase().replaceAll(' ', '-') === tab
  )
  const [activeTab, setActiveTab] = useState<number>(
    tabIndex < 0 ? 0 : tabIndex
  )

  // active tab context is only available to those who uses it. It's purpose is to allow our filters to detect tab 'change'
  let activeTabContext: any
  try {
    activeTabContext = useActiveTab()
  } catch (e) {
    // Context is not available, skip using it
    activeTabContext = null
  }

  // When number of tabs exceeds 4, we change to dropdown format
  const showDropdown = tabs.length > 4 && switchable

  useEffect(() => {
    const nextActiveTab =
      tabIndex < 0 || tabIndex > tabs.length - 1 ? 0 : tabIndex
    setActiveTab(nextActiveTab)
    if (activeTabContext) {
      activeTabContext.setActiveContextTab(nextActiveTab)
    }

    // force click
    setTimeout(() => {
      const tabElem = document.querySelector(
        `[data-value="${
          tabs[nextActiveTab]?.id ?? tabs[nextActiveTab]?.label
        }"]`
      ) as HTMLElement
      if (tabElem) {
        tabElem.click()
      }
    }, 100)
  }, [JSON.stringify(tabs.map(x => x.label)), tab])
  // We prioritize ID over label, using ID logic (e.g for multi tabs) to swap first before converting page name.
  const swapTabs = (idx: number, label: string) => {
    setActiveTab(idx)
    if (activeTabContext) {
      activeTabContext.setActiveContextTab(idx)
    }
    const newSearchParams = new URLSearchParams(searchParams)
    if (Number(label)) {
      newSearchParams.set('tab', label)
    } else {
      newSearchParams.set('tab', label.toLowerCase().replaceAll(' ', '-'))
    }
    company && newSearchParams.set('pid', company?.slug_name)
    setSearchParams(newSearchParams.toString())
  }

  const pageTitle = tabs[activeTab]?.label

  return (
    <BaseLayout
      title={title}
      filters={filters}
      disableReset={disableReset}
      rightFooterContent={rightFooterContent}
    >
      <Card className="rounded-md">
        <CardBody className="p-0 flex flex-col ">
          {tabs.length === 0 && empty && <div className="p-6">{empty}</div>}
          {tabs.length === 0 && null}
          {tabs.length > 0 && showDropdown && (
            <div className="p-6 flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <div className="whitespace-nowrap font-semibold text-lg text-cc-text-primary w-500">
                  {pageTitle}
                </div>
                <Select
                  className=" [&~ul]:max-h-[200px] !w-auto ml-auto"
                  onChange={val => {
                    const idx = Number(val)
                    swapTabs(idx, tabs[idx].id ?? tabs[idx].label)
                  }}
                  selected={() => tabs[activeTab].label}
                >
                  {tabs.map(({ label }, idx) => (
                    <Option key={idx} value={idx.toString()}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="">{tabs[activeTab]?.component}</div>
            </div>
          )}
          {tabs.length > 0 && !showDropdown && (
            <Tabs value={tabs[activeTab]?.label} className="rounded-md">
              <TabsHeader
                className="z-0 rounded-none bg-neutral-white p-0 flex items-end"
                indicatorProps={{
                  className: 'shadow-none !text-gray-900',
                }}
              >
                {tabs.map(({ id, label, headerClassName = '' }, idx) => (
                  <Tab
                    key={id ?? label}
                    value={id ?? label}
                    onClick={() => {
                      swapTabs(idx, id ?? label)
                    }}
                    className={`${headerClassName} ${
                      (id ?? label) ===
                      (tabs[activeTab]?.id ?? tabs[activeTab]?.label)
                        ? 'font-semibold text-primary-pressed border-b-[2px] border-b-primary-hover'
                        : 'border-b-neutral-border-1 box-border border-b-[1.5px] hover:text-black hover:border-b-[1.5px] hover:border-b-cc-primary-gray-dark '
                    } px-3 py-5 w-[200px] flex-1`}
                  >
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody>
                {tabs.map(({ id, label, component }) => (
                  <TabPanel
                    key={id ?? label}
                    value={id ?? label}
                    className="z-0 p-6"
                  >
                    {component}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          )}
        </CardBody>
      </Card>
    </BaseLayout>
  )
}

export default L3TabsLayout
