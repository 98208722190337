import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import moment from 'moment'
import { createRoot } from 'react-dom/client'

import * as am5 from '@amcharts/amcharts5'
import { IExportingDataOptions } from '@amcharts/amcharts5/.internal/plugins/exporting/Exporting'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive'
import * as am5xy from '@amcharts/amcharts5/xy'
import { ILineSeriesAxisRange } from '@amcharts/amcharts5/xy'
import { formatUnixSeries } from '@helpers/unix-converter'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Alert } from '@material-tailwind/react'

import PrecacheLoadingState from './chartHelper'

/**
 * @todo
 * extract from tailwind theme
 */
export const chartColors = [
  '#ffa557', // light warm ember - custom
  '#0288a7', // aqua - core
  '#b158a8', // plum - core
  '#9d6d41', // leather - core
  '#85bce3', // sky - core
  '#8fb09c', // mint - core
  '#59ce87', // jade - core
  '#757d90', // slate - core
  '#ff6969', // coral - core
  '#EFC88B', // lighter orange - noncore
  '#244654', // dark navy - noncore
  '#E7C440', // yellow - noncore
  '#CEDDE7', // light grey - non-core
  '#f4e3b2', // vanillla - noncore
  '#342E37', // dark purple - noncore
  '#4A3C35', // dark brown - noncore
]

export interface SeriesData {
  /**
   * x value
   */
  x: number | string
  /**
   * series value
   */
  [key: string]: number | string | undefined
}

interface BaseChartProps {
  /**
   * chart data. should include field from all series
   */
  data: SeriesData[]
  /**
   * yAxis Label
   */
  yLabel: string
  /**
   * secondary Y Label config. only pass if secondary Y axis exists
   */
  secondaryYConfig?: {
    secondaryYLabel: string
    secondaryYFormat: string
  }
  /**
   * yAxis format
   */
  yFormat?: string
  /**
   * optional yAxis Setting
   */
  ySetting?: {
    [key: string]: any
  }
  /**
   * xAxis Label
   */
  xLabel?: string
  /**
   * xAxis Type
   */
  xAxisType?: 'DateAxis' | 'CategoryAxis'
  /**
   * optional xAxis Setting
   */
  xSetting?: {
    [key: string]: any
  }
  /**
   * series list
   */
  series: {
    /**
     * series label. used as legend and tooltip
     */
    label: string
    /**
     * series label. used to override tooltip
     */
    tooltipLabel?: string
    /**
     * series tooltip format
     */
    tooltipValueFormat?: string
    /**
     * series type
     */
    type: 'ColumnSeries' | 'LineSeries' | 'SmoothedXLineSeries'
    /**
     * whether series is stack or not. only applicable for ColumnSeries type.
     */
    isStack?: boolean
    /**
     * Only applicable for Stack ColumnSeries type.
     * whether stack is aggregated or not
     * True by default
     */
    isTotal?: boolean
    /**
     * series color.
     * will applied to stack fill color for column / stacked series,
     * or stroke color for line series
     * format should be in hexadecimal eg 0xff0000
     * or hex string #FF0000
     */
    color?: number | string
    /**
     * enables gradient from the given fill/stroke colour
     * */
    gradient?: boolean
    /**
     * enables fill from the given colour
     * */
    fill?: boolean
    /**
     * plots a thicker distinguishable series
     * */
    isSpecial?: boolean
    /**
     * field key name
     */
    field: string
    /**
     * whether series has bullet or not. only applicable for SmoothedXLineSeries
     */
    hasBullet?: boolean
    /**
     * defines bullet shape
     */
    bulletType?: 'line' | 'circle'
    /**
     * function to set each item color dynamicaly
     * passing dataitem
     * return color hex or string
     */
    setColor?: (field: string, data: any) => number | string
    /**
     * moves Yaxis to opposite direction
     */
    oppositeYAxis?: boolean
  }[]
  /**
   * use as element distinguisher
   */
  id: string
  /**
   * chart height
   */
  height?: number // can use tailwind in parent component?
  /**
   * legend setting
   */
  legendSetting?: {
    show?: boolean
    position?: string
    config?: {
      [key: string]: any
    }
  }
  /**
   * scrollbar setting
   */
  scroll?: {
    y?: boolean
    x?: boolean
    xStart?: number
    xEnd?: number
  }
  /**
   * range setting
   */
  range?: {
    from: number
    to: number
    label?: string
    color?: string | number
  }[]
  /**
   * tooltip position
   */
  tooltipPosition?: 'top' | 'bottom'
  /**
   * tooltip subtitle
   * currently used to show historical fx rate
   */
  tooltipSubtitle?: {
    field: string
    title: string
  }[]
  /**
   * whether show export button
   */
  exportable?: boolean
  exportableColumn?: any[]
  // property that will be used to group the data
  exportableGroupBy?: string
  xlsxOptions?: IExportingDataOptions

  /**
   * makes the chart horizontal
   */
  isHorizontal?: boolean
}

const BaseChart = (props: BaseChartProps) => {
  const {
    data,
    yLabel,
    yFormat = '#.00a',
    ySetting,
    xLabel = 'Month',
    xAxisType = 'DateAxis',
    xSetting,
    series: seriesProps,
    id,
    legendSetting = {
      show: true,
      position: 'top',
    },
    scroll = {
      x: true,
      y: true,
    },
    range,
    tooltipPosition = 'top',
    tooltipSubtitle,
    exportable = true,
    exportableColumn,
    exportableGroupBy,
    xlsxOptions,
    isHorizontal,
    secondaryYConfig,
  } = props

  // if data length is 0, we start with loading. if still loading after 5s, show no data available
  if (data.length === 0) {
    return <PrecacheLoadingState data={data} height={props.height} />
  }

  const [exportDivAvailable, setExportDivAvailable] = useState(false)

  const series = seriesProps.map(x => ({
    ...x,
    label: (x.label ?? '-').replace(/[\[\]']+/g, ''),
    tooltipLabel: x.tooltipLabel?.replace(/[\[\]']+/g, ''),
  }))
  const seriesRefs = useRef<
    Array<am5xy.ColumnSeries | am5xy.LineSeries | am5xy.SmoothedXLineSeries>
  >([])
  const xAxisRef = useRef<
    | am5xy.DateAxis<am5xy.AxisRenderer>
    | am5xy.CategoryAxis<am5xy.AxisRenderer>
    | am5xy.ValueAxis<am5xy.AxisRenderer>
    | null
  >(null)
  const yAxisRef = useRef<
    | am5xy.DateAxis<am5xy.AxisRenderer>
    | am5xy.CategoryAxis<am5xy.AxisRenderer>
    | am5xy.ValueAxis<am5xy.AxisRenderer>
    | null
  >(null)

  function replaceNodeWithReactComponent(child?: HTMLElement) {
    if (child) {
      const parent = document.createElement('div')
      const reactComponent = (
        <div className="flex flex-row w-full px-3 my-1">
          <ArrowDownTrayIcon className="w-7 h-7 pr-2" />{' '}
          <span className="flex text-sm font-medium items-center">Export</span>
        </div>
      )
      const root = createRoot(parent)
      root.render(reactComponent)
    }
  }

  // Graph Chart Root
  const rootRef = useRef<am5.Root | null>(null)
  useLayoutEffect(() => {
    const root = am5.Root.new(`chart-${id}`)

    root.numberFormatter.setAll({
      bigNumberPrefixes: [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
        { number: 1e12, suffix: 'T' },
        { number: 1e15, suffix: 'Q' },
      ],
      smallNumberPrefixes: [],
    })

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
    ])

    root.container.setAll({
      paddingTop: -7,
      paddingBottom: -20,
      paddingLeft: -30,
    })

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        layout: root.verticalLayout,
        maxTooltipDistance: -1,
        paddingRight: -10,
      })
    )

    // zoom out button settings
    if (chart.zoomOutButton) {
      const background = chart.zoomOutButton.get('background')

      if (background) {
        background.setAll({
          fill: am5.color(0x302a34),
          fillOpacity: 1,
        })

        const hoverState = background.states.create('hover', {})
        hoverState.setAll({
          fill: am5.color(0xff7e35), // Hover fill color (e.g., a lighter shade)
        })

        const activeState = background.states.create('active', {})
        activeState.setAll({
          fill: am5.color(0xff7e35), // Keep the same color as the default or set your preferred color
        })
      }
    }

    // cursor
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomX',
      })
    )

    const yAxisRenderer = am5xy.AxisRendererY.new(root, {})

    yAxisRenderer.labels.template.setAll({
      fontSize: ySetting?.fontSize ?? 12,
      fill: am5.color(0x757575),
      paddingBottom: 10,
      lineHeight: 1.5,
      paddingRight: 10,
    })

    const isTotalStackChart =
      series.filter(
        ({ isStack, type, isTotal = true }) =>
          isStack &&
          (type === 'ColumnSeries' || type === 'SmoothedXLineSeries') &&
          isTotal
      ).length === series.length

    const axisTooltip =
      tooltipPosition === 'bottom'
        ? {
            tooltip: am5.Tooltip.new(root, {}),
          }
        : {}
    let yAxis: any
    let xAxis: any

    const auxAxisSetting = {
      min: isTotalStackChart ? 0 : ySetting?.minVal ?? 0,
      max: isTotalStackChart ? 100 : ySetting?.maxVal < 0 ? 0 : undefined,
      strictMinMax: isTotalStackChart,
      calculateTotals: isTotalStackChart,
    }

    if (!isHorizontal) {
      yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yAxisRenderer,
          numberFormat: yFormat,
          ...auxAxisSetting,
          ...ySetting,
        })
      )
    } else {
      yAxis =
        xAxisType === 'DateAxis'
          ? chart.yAxes.push(
              am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: 'month', count: 1 },
                groupData: false,
                renderer: yAxisRenderer,
                dateFormats: {
                  day: 'MMM/dd',
                  month: 'MMM-yy',
                  year: 'yyyy',
                },
                periodChangeDateFormats: {
                  day: 'MMM/dd',
                  month: 'MMM-yy',
                },
                ...xSetting?.dateAxis,
                ...axisTooltip,
              })
            )
          : chart.yAxes.push(
              am5xy.CategoryAxis.new(root, {
                renderer: yAxisRenderer,
                categoryField: 'x',
                ...axisTooltip,
              })
            )
    }

    // Y-Axis Title
    chart.leftAxesContainer.children.unshift(
      am5.Label.new(root, {
        text: isHorizontal ? xLabel : yLabel,
        fontSize: ySetting?.fontSize ?? 12,
        fontWeight: '500',
        fontFamily: 'Inter',
        rotation: -90,
        textAlign: 'center',
        centerY: am5.p50,
        y: am5.p50,
        fill: am5.color(0x2b2b2b),
        marginRight: 4,
      })
    )

    // Create X-axis Block
    const xAxisRenderer = am5xy.AxisRendererX.new(root, {
      ...xSetting?.renderer,
    })

    // Adjust the X-axis Labels
    xAxisRenderer.labels.template.setAll({
      fontSize: xSetting?.fontSize ?? 12,
      fill: am5.color(0x757575),
      background: am5.Graphics.new(root, {
        fill: am5.color(0xff0000),
      }),
    })

    // Create X-Axis
    if (isHorizontal) {
      xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xAxisRenderer,
          numberFormat: yFormat,
          ...auxAxisSetting,
          ...xSetting,
        })
      )
    } else {
      xAxis =
        xAxisType === 'DateAxis'
          ? chart.xAxes.push(
              am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: 'month', count: 1 },
                groupData: false,
                renderer: xAxisRenderer,
                dateFormats: {
                  day: 'MMM-dd',
                  month: 'MMM-yy',
                  year: 'yyyy',
                },
                periodChangeDateFormats: {
                  day: 'MMM/dd',
                  month: 'MMM-yy',
                },
                ...xSetting?.dateAxis,
                ...axisTooltip,
              })
            )
          : chart.xAxes.push(
              am5xy.CategoryAxis.new(root, {
                renderer: xAxisRenderer,
                categoryField: 'x',
                ...axisTooltip,
              })
            )
    }

    // Adjust the X-axis Title
    xAxis.children.push(
      am5.Label.new(root, {
        text: isHorizontal ? yLabel : xLabel,
        fontSize: xSetting?.fontSize ?? 12,
        centerX: am5.p50,
        x: am5.p50,
        fill: am5.color(0x2b2b2b),
        fontWeight: '500',
        fontFamily: 'Inter',
        marginTop: 4,
      })
    )

    if (tooltipPosition === 'bottom') {
      ;(xAxis as any).get('tooltip')?.label.adapters.add('text', () => {
        let text = ''
        chart.series.each((s, idx) => {
          const dataItem = s.get('tooltipDataItem')
          const _s = series[idx]
          if (dataItem) {
            if (idx === 0) {
              text += `[bold]${
                xAxisType === 'DateAxis'
                  ? moment
                      .utc(dataItem.get('valueX') ?? 0)
                      .format(
                        ['day', 'week'].includes(
                          xSetting?.dateAxis?.baseInterval?.timeUnit
                        )
                          ? 'DD-MMM-yy'
                          : 'MMM-yy'
                      )
                  : dataItem.get('valueX')
              }[/]\n`
            }
            text += `[#98A2B3]${_s.tooltipLabel ?? _s.label}[/] : [${
              _s.setColor ? '#98A2B3' : chartColors[idx] ?? _s.color
            }]${root.numberFormatter.format(
              dataItem.get('valueY') ?? 0,
              _s.tooltipValueFormat || '#.00a'
            )}[/]\n`
          }
        })
        return text
      })
      ;(xAxis as any).get('tooltip')?.label.setAll({
        fontSize: 12,
        fill: am5.color(0x0e9eda),
      })
      ;(xAxis as any)
        .get('tooltip')
        .get('background')
        ?.setAll({
          fill: am5.color(0xffffff),
          stroke: am5.color(0x98a2b3),
        })
    }

    xAxisRef.current = xAxis
    yAxisRef.current = yAxis

    // Create Series
    const tooltipColumn = Math.ceil(series.length / 20)
    series.forEach((s, i) => {
      let tooltip
      if (tooltipPosition === 'top') {
        // This is the string displayed in the hover, initialized with nothing and aggregated
        let labelHTML = ''
        series.forEach((_s, _i) => {
          labelHTML += `<div class="flex text-xs gap-1"><span>${
            _s.tooltipLabel ?? _s.label
          }:</span><span class="font-bold" style="color:${
            _s.setColor ? '#98A2B3' : chartColors[_i] ?? _s.color
          }">{${_s.field}.formatNumber("${
            s.tooltipValueFormat || '#.00a'
          }")}</span></div>`
        })
        // ${_s.field}.formatNumber("${ s.tooltipValueFormat || '#.00a'}
        tooltip = am5.Tooltip.new(root, {
          autoTextColor: false,
          getFillFromSprite: false,
          labelHTML: `<div class="flex flex-col gap-2"><span class="text-xs font-bold">${
            isHorizontal ? yLabel : xLabel
          } {${
            xAxisType === 'DateAxis'
              ? ['day', 'week'].includes(
                  xSetting?.dateAxis?.baseInterval?.timeUnit
                )
                ? 'x.formatDate("dd-MMM-yy")'
                : 'x.formatDate("MMM-yy")'
              : 'x'
          }}</span>${
            tooltipSubtitle
              ? tooltipSubtitle
                  .map(
                    t =>
                      `<span class="text-xs">${t.title}: <span class="font-bold">{${t.field}}</span></span>`
                  )
                  .join('')
              : ''
          }<div class="grid gap-1" style="grid-template-columns: repeat(${tooltipColumn}, minmax(0, 1fr))">${labelHTML}</div><div>`,
        })

        tooltip.label.setAll({
          fontSize: 12,
          fill: am5.color(0x0e9eda),
        })

        tooltip.get('background')?.setAll({
          fill: am5.color(0xffffff),
          stroke: am5.color(0x98a2b3),
        })
      }

      let chartSeries: any
      let yAxisOpposite: any

      const fields = isHorizontal
        ? {
            valueXField: s.field,
            categoryYField: 'x',
          }
        : {
            categoryXField: 'x',
            valueXField: 'x',
            valueYField: s.field,
            ...(s.isTotal && { valueYShow: 'valueYTotalPercent' }),
          }

      // For "horizontal" trigger line
      const commonSeriesOptions = {
        name: s.label,
        xAxis: xAxis,
        yAxis: s.oppositeYAxis ? yAxisOpposite : yAxis,
        baseAxis: isHorizontal ? yAxis : xAxis,
        ...fields,
        stacked:
          (s.type === 'ColumnSeries' || s.type === 'SmoothedXLineSeries') &&
          s.isStack,
        fill: s.setColor ? am5.color('#98A2B3') : chartColors[i] ?? s.color,
        stroke: s.setColor ? am5.color('#98A2B3') : chartColors[i] ?? s.color,
        tooltip,
      }

      if (s.oppositeYAxis) {
        chart.rightAxesContainer.children.unshift(
          am5.Label.new(root, {
            text: isHorizontal ? xLabel : secondaryYConfig?.secondaryYLabel,
            fontSize: ySetting?.fontSize ?? 12,
            fontWeight: '400',
            fontFamily: 'Inter',
            rotation: -90,
            textAlign: 'center',
            centerY: am5.p50,
            y: am5.p50,
            fill: am5.color(0x757575),
          })
        )
        const yAxisRendererOpposite = am5xy.AxisRendererY.new(root, {
          opposite: true,
        })
        yAxisRendererOpposite.labels.template.setAll({
          fontSize: ySetting?.fontSize ?? 12,
          fill: am5.color(0x757575),
          paddingBottom: 10,
          lineHeight: 1.5,
          paddingRight: 10,
        })

        yAxisOpposite = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: yAxisRendererOpposite,
            numberFormat: secondaryYConfig?.secondaryYFormat,
            ...auxAxisSetting,
            ...ySetting,
          })
        )

        chartSeries = chart.series.push(
          am5xy[s.type].new(root, {
            ...commonSeriesOptions,
            yAxis: yAxisOpposite,
          })
        )
      } else {
        chartSeries = chart.series.push(
          am5xy[s.type].new(root, {
            ...commonSeriesOptions,
          })
        )
      }
      if (s.fill) {
        ;(chartSeries as am5xy.LineSeries).fills.template.setAll({
          fillOpacity: 1,
          visible: true,
        })
      }

      if (s.gradient) {
        ;(chartSeries as am5xy.LineSeries).fills.template.set(
          'fillGradient',
          am5.LinearGradient.new(root, {
            stops: [
              {
                color: am5.color(chartColors[i]),
                opacity: 1,
              },
              {
                color: am5.color(chartColors[i]),
                opacity: 0.2,
              },
            ],
          })
        )
        ;(chartSeries as am5xy.LineSeries).fills.template.setAll({
          visible: true,
          fillOpacity: 1,
        })
      }
      if (
        ['LineSeries', 'SmoothedXLineSeries', 'StepLineSeries'].includes(s.type)
      ) {
        ;(chartSeries as am5xy.LineSeries).strokes.template.setAll({
          strokeWidth: s.isSpecial ? 4 : 2,
          strokeDasharray: s.isSpecial ? [5, 2] : undefined,
        })
      }
      const hasBullet = s.hasBullet ?? true

      if (s.type === 'SmoothedXLineSeries' && hasBullet) {
        ;(chartSeries as am5xy.SmoothedXLineSeries).bullets.push(() => {
          return am5.Bullet.new(root, {
            locationY: 1,
            sprite:
              s.bulletType == 'line'
                ? am5.Graphics.new(root, {
                    stroke: am5.color(chartColors[i] ?? s.color),
                    strokeWidth: 2,
                    draw: function (display) {
                      isHorizontal
                        ? display.lineTo(0, 400)
                        : display.lineTo(400, 0)

                      display.lineTo(0, 0)
                    },
                  })
                : am5.Circle.new(root, {
                    radius: 4,
                    stroke: am5.color(chartColors[i] ?? s.color),
                    strokeWidth: 2,
                    fill: am5.color(0xffffff),
                  }),
          })
        })
      }

      // Called only if s.setColor is a function
      if (s.setColor !== undefined && typeof s.setColor === 'function') {
        ;(chartSeries as any).columns?.template.adapters.add(
          'fill',
          (fill: any, target: any) => {
            return s.setColor?.(s.field, target.dataItem?.dataContext)
          }
        )
        ;(chartSeries as any).columns?.template.adapters.add(
          'stroke',
          (stroke: any, target: any) => {
            return s.setColor?.(s.field, target.dataItem?.dataContext)
          }
        )
      }

      seriesRefs.current[i] = chartSeries

      // Create axis ranges
      if (range && range.length > 0) {
        range.forEach(r => {
          const seriesRangeDataItem = yAxis.makeDataItem({
            value: r.from,
            endValue: r.to,
          })
          const seriesRange = chartSeries.createAxisRange(seriesRangeDataItem)
          ;(seriesRange as ILineSeriesAxisRange).fills?.template.setAll({
            visible: true,
          })
          ;(seriesRange as ILineSeriesAxisRange).fills?.template.set(
            'fill',
            am5.color(r.color ?? 0xff9500)
          )
          ;(seriesRange as ILineSeriesAxisRange).strokes?.template.set(
            'stroke',
            am5.color(r.color ?? 0xff9500)
          )

          seriesRangeDataItem.get('grid')?.setAll({
            strokeOpacity: 1,
            visible: true,
            stroke: am5.color(r.color ?? 0xff9500),
            // strokeDasharray: [10, 10],
            strokeWidth: 2,
          })

          if (r.label) {
            seriesRangeDataItem.get('label')?.setAll({
              location: 0,
              visible: true,
              text: r.label,
              inside: true,
              centerX: 0,
              centerY: am5.p100,
            })
          }
        })
      }
    })

    const hasLineBullets =
      series.filter(item => item.bulletType === 'line').length > 0

    // Legend
    if (series.length > 1 && legendSetting.show) {
      const legendItem = am5.Legend.new(root, {
        ...(['left', 'right'].includes(legendSetting?.position ?? 'top')
          ? {
              layout: root.gridLayout,
              x: am5.percent(50),
              centerX: am5.percent(50),
              useDefaultMarker: hasLineBullets,
              height: am5.percent(100),
              verticalScrollbar: am5.Scrollbar.new(root, {
                orientation: 'vertical',
              }),
            }
          : {
              layout: root.gridLayout,
              y: am5.percent(50),
              centerY: am5.percent(50),
              useDefaultMarker: hasLineBullets,

              paddingBottom: 24,
            }),
        ...legendSetting.config,
      })
      let legend
      if (legendSetting?.position === 'right') {
        legend = chart.rightAxesContainer.children.push(legendItem)
        root.container._settings.paddingRight = -24
      } else if (legendSetting?.position === 'bottom') {
        legend = chart.bottomAxesContainer.children.push(legendItem)
      } else if (legendSetting?.position === 'left') {
        legend = chart.leftAxesContainer.children.unshift(legendItem)
      } else {
        legend = chart.topAxesContainer.children.push(legendItem)
      }

      legend?.markers.template.setAll({
        width: 12,
        height: 12,
      })
      legend?.labels.template.setAll({
        fontSize: 12,
        fill: am5.color(0x757575),
      })

      legend?.data.setAll(chart.series.values)
    }

    const scrollGripScale = 0.6

    // Scrollbar X
    if (scroll.x) {
      const scrollbarX = am5.Scrollbar.new(root, {
        orientation: 'horizontal',
        ...(scroll.xStart ? { start: scroll.xStart } : {}),
        ...(scroll.xEnd ? { end: scroll.xEnd } : {}),
        height: 4,
        marginBottom: 5,
      })
      scrollbarX.thumb.setAll({
        height: 4,
        fill: am5.color(0xe4e7ec),
      })
      scrollbarX.startGrip.setAll({
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xffffff),
          stroke: am5.color(0xe4e7ec),
        }),
        scale: scrollGripScale,
      })
      scrollbarX.endGrip.setAll({
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xffffff),
          stroke: am5.color(0xe4e7ec),
        }),
        scale: scrollGripScale,
      })

      chart.set('scrollbarX', scrollbarX)
      chart.bottomAxesContainer.children.unshift(scrollbarX)
      chart.bottomAxesContainer.setAll({
        marginTop: -4,
      })
    }

    // Scrollbar Y
    if (scroll.y) {
      const scrollbarY = am5.Scrollbar.new(root, {
        orientation: 'vertical',
        width: 4,
      })
      scrollbarY.thumb.setAll({
        width: 4,
        fill: am5.color(0xe4e7ec),
      })
      scrollbarY.startGrip.setAll({
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xffffff),
          stroke: am5.color(0xe4e7ec),
        }),
        scale: scrollGripScale,
      })
      scrollbarY.endGrip.setAll({
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xffffff),
          stroke: am5.color(0xe4e7ec),
        }),
        scale: scrollGripScale,
      })
      chart.set('scrollbarY', scrollbarY)
      chart.rightAxesContainer.children.push(scrollbarY)
      chart.leftAxesContainer.setAll({
        paddingRight: 2,
      })
      chart.rightAxesContainer.setAll({
        marginLeft: 0,
      })
    }

    root.timezone = am5.Timezone.new('Etc/Universal')

    root._logo?.dispose()

    rootRef.current = root

    return () => {
      root.dispose()
      rootRef.current = null
      if (exportable) {
        replaceNodeWithReactComponent(
          document.getElementsByClassName('am5exporting-icon')[0]
            ?.children[0] as HTMLElement
        )
      }
    }
  }, [])

  /* Exportable Functionalities */
  // Recurssively check for exportDiv availability
  useEffect(() => {
    const checkExportDiv = () => {
      // Export is a reference of "exportdiv" from BaseLayout
      const exportDiv = document.getElementById('exportdiv')
      if (exportDiv && data[0] && exportable) {
        setExportDivAvailable(true)
      } else {
        setTimeout(checkExportDiv, 100) // Retry after 100ms
      }
    }

    checkExportDiv()
  }, [])

  useEffect(() => {
    const root = rootRef.current
    const exportDiv = document.getElementById('exportdiv')

    // Wait until the root and exportDiv are initialized
    if (root && exportDiv && exportDivAvailable) {
      let dataFields = {}

      if (exportableColumn) {
        dataFields = exportableColumn?.reduce((p, ec) => {
          return { ...p, [ec.field]: ec.title }
        }, {})
      } else {
        dataFields = Object.keys(data[0]).reduce(
          (acc: { [key: string]: string }, current: string) => {
            acc[current] =
              series.find((si: any) => si.field == current)?.label ?? current
            return acc
          },
          {}
        )
      }

      const tempExportableColumnData: any[] = []
      let exportableColumnData: any[] = []

      if (exportableColumn) {
        tempExportableColumnData.push(
          exportableColumn.reduce((accu, curr) => {
            accu[curr.field] = curr.title
            return accu
          }, {})
        )
        tempExportableColumnData.push(
          ...data.map(d => {
            return exportableColumn?.reduce((p, ec) => {
              return { ...p, [ec.field]: ec?.render?.(d, p) ?? d[ec.field] }
            }, {})
          })
        )
        if (exportableGroupBy) {
          groupExportedData(
            exportableColumnData,
            tempExportableColumnData,
            exportableGroupBy
          )
        } else {
          exportableColumnData = tempExportableColumnData
        }
      }

      am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          useDefaultCSS: false,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          container: exportDiv,
        }),
        filePrefix: `${id}_${moment().format()}`,
        dataSource: exportableColumn
          ? exportableColumnData
          : xAxisType === 'DateAxis'
          ? formatUnixSeries(data)
          : data,
        dataFields,
        xlsxOptions,
      })
    }
  }, [exportDivAvailable])

  useLayoutEffect(() => {
    if (exportable) {
      replaceNodeWithReactComponent(
        document.getElementsByClassName('am5exporting-icon')[0]
          ?.children[0] as HTMLElement
      )
    }
  }, [])

  useLayoutEffect(() => {
    const sorted_data = data.sort((a, b) =>
      typeof a.x === 'number' ? (Number(a.x) > Number(b.x) ? 1 : -1) : 0
    )
    !isHorizontal
      ? xAxisRef.current?.data.setAll(sorted_data)
      : yAxisRef.current?.data.setAll(sorted_data)
    series.forEach((s, i) => {
      seriesRefs?.current?.[i]?.data.setAll(sorted_data)
    })
  }, [data])

  // Dynamically calculate legend height
  const longestLabelLength = Math.max(
    ...series.map(s => (s.label ?? '').length)
  )
  let legendHeight = 70
  if (series.length > 10) {
    if (longestLabelLength > 30) {
      legendHeight += (series.length - 1) * 30
    } else {
      legendHeight += (series.length - 1) * 10
    }
  } else {
    if (longestLabelLength > 30) {
      legendHeight += (series.length - 1) * 15
    } else {
      legendHeight += 15
    }
  }

  const minHeight =
    props.height ??
    450 +
      (['top', 'bottom'].includes(legendSetting?.position ?? 'top')
        ? legendHeight
        : 0)

  return (
    <div
      id={`chart-${props.id}`}
      style={{ minHeight }}
      className={`w-full`}
    ></div>
  )
}

interface ChartProps extends BaseChartProps {
  /**
   * loading state
   */
  loading: boolean
  /**
   * error
   */
  error?: {
    message: string
  }
}
const Chart = (props: ChartProps) => {
  const { loading, error, ...rest } = props
  if (loading || error) {
    return (
      <div
        style={props.height ? { minHeight: props.height } : {}}
        className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
      >
        {loading ? (
          // loading animation
          <ArrowPathIcon className="animate-spin text-primary-main w-8" />
        ) : (
          <Alert className="w-1/2 text-danger-main border border-danger-main text-center">
            {error?.message}
          </Alert>
        )}
      </div>
    )
  }
  return <BaseChart {...rest} />
}

export default memo(
  Chart,
  /**
   * return true will prevent rerender
   */
  (prevProps, nextProps) => {
    return (
      prevProps.loading == nextProps.loading &&
      prevProps.error == nextProps.error
    )
  }
)

const groupExportedData = (
  exportableColumnData: any[],
  tempExportableColumnData: any[],
  exportableGroupBy: string
) => {
  // build a map with the key of `exportableGroupBy` property on the struct
  const groupMap: Map<string, any[]> = new Map<string, any[]>()
  tempExportableColumnData.forEach(v => {
    const key = v[`${exportableGroupBy}`]
    if (!groupMap.has(key)) {
      groupMap.set(key, [])
    }
    groupMap.get(key)?.push(v)
  })
  // reduce group of structs with the same property to only 1 object
  new Map(
    [...groupMap.entries()].sort((l, r) => {
      if (
        String(l[0]).toLocaleLowerCase() ==
        exportableGroupBy.toLocaleLowerCase()
      ) {
        return -1
      } else if (
        String(r[0]).toLocaleLowerCase() ==
        exportableGroupBy.toLocaleLowerCase()
      ) {
        return 1
      } else {
        return String(l[0]).localeCompare(String(r[0]))
      }
    })
  ).forEach(val => {
    const obj = val.reduce((accumulator, currentValue) => {
      const temp: any = {}
      for (const prop in accumulator) {
        temp[prop] = accumulator[prop] ?? currentValue[prop]
        delete currentValue[prop]
      }
      return { ...temp, ...currentValue }
    })
    exportableColumnData.push(obj)
  })
}
