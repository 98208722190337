import React, { ReactChild, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useFlaggedRoutes } from '@components/app-routes/routes'
import NewFeatureIcon from '@components/feature-instructions/new-feature-icon'
import AuthContext from '@contexts/auth'
import { IconButton, Tooltip } from '@material-tailwind/react'
interface SidebarProps {
  /**
   * whether L1 navigation is expanded or not
   */
  expanded: boolean
}

const IconTemplate = ({
  features,
  expanded,
  className,
}: {
  features: any
  expanded: SidebarProps['expanded']
  className?: string
}) => {
  return (
    <div
      className={`${className ? className : ''} ${
        expanded ? 'visible' : 'invisible'
      } lg:visible flex flex-col items-center gap-3`}
      data-testid="sidebar-menu"
    >
      {features}
    </div>
  )
}

// Use this wrapper if you want your feature to have "NEW" icon beside it. Please fill with appropriate icon name and desired local storage tag.
const NFSidebarLogicWrapper = ({
  visitedIcon,
  localTag,
  visited,
  children,
}: {
  visitedIcon: string
  localTag: string
  visited: Set<string>
  children: ReactChild
}) => {
  return (
    <div className="relative">
      {!visited.has(visitedIcon) && (
        <NewFeatureIcon localTag={localTag} className="" />
      )}
      {children}
    </div>
  )
}

const Sidebar = ({ expanded }: SidebarProps) => {
  const [visitedIcon, setVisitedIcon] = useState<Set<string>>(new Set())

  const navigate = useNavigate()
  const location = useLocation()

  const context = useContext(AuthContext)

  const getFlaggedRoutes = useFlaggedRoutes(context, true)
  const flaggedRouting = getFlaggedRoutes()

  const renderRoute = (r: any) => {
    {
      const isActive = location.pathname.split('/')[1] == r.path
      const Icon = isActive ? r.activeIcon : r.icon
      return (
        <Tooltip
          key={r.path}
          content={r.title}
          placement="right"
          className="bg-white border border-cc-primary-border shadow-xl text-cc-text-secondary text-[12px]"
        >
          <IconButton
            data-testid={r.path}
            variant="text"
            className={`w-[50px]  !important h-[50px] !important hover:bg-cc-secondary-hover-deselected transition-none rounded-md ${
              isActive ? 'bg-cc-secondary-hover-selected' : ''
            }`}
            onClick={() => {
              navigate(r.path)
              setVisitedIcon(prev => new Set([Array.from(prev), r.title]))
            }}
          >
            <Icon className="w-6 text-primary-main" />
          </IconButton>
        </Tooltip>
      )
    }
  }

  const applications = flaggedRouting
    .filter(r => r.path !== 'notification')
    .map(r => renderRoute(r))

  const notification = flaggedRouting
    .filter(r => r.path === 'notification')
    .map(r => renderRoute(r))

  return (
    <div
      className={`bg-neutral-white flex flex-col w-sidebar h-full z-13 py-4 gap-5 z-10`}
      id="sidebar"
    >
      <div className=" flex justify-center items-center mt-[5px]">
        <img
          src="/svgs/cascade-logo.svg"
          alt="Cascade"
          data-testid="cascade-logo"
          className="w-[35px] h-[35px]"
        />
      </div>
      <div
        className={`flex flex-col flex-1 gap-3 ${
          expanded ? 'overflow-y-auto' : ''
        }`}
      >
        <IconTemplate features={applications} expanded={expanded} />

        {/* bottom icons */}
        <div className="mt-auto">
          <NFSidebarLogicWrapper
            visitedIcon="Notification Center"
            visited={visitedIcon}
            localTag="hasSeenNotification"
          >
            <IconTemplate features={notification} expanded={expanded} />
          </NFSidebarLogicWrapper>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
