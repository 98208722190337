import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import UsersPage from 'src/pages/administration/setting/users'
import LoantapeOverviewL3 from 'src/pages/analytics/data-exports/loantape'
import CharacteristicPage from 'src/pages/analytics/risk/characteristic'
import CohortPage from 'src/pages/analytics/risk/cohort'
import CollectionPage from 'src/pages/analytics/risk/collection'
import DelinquencyPage from 'src/pages/analytics/risk/delinquency'
import RiskIndicatorsL3 from 'src/pages/analytics/risk/indicators'
import RiskOverviewL3 from 'src/pages/analytics/risk/overview'
import TractionPage from 'src/pages/analytics/risk/traction'
import MonitorOverviewL3 from 'src/pages/manage/monitor/overview'

import {
  DataValidationIconOutline,
  DataValidationIconSolid,
} from '@components/other/data-validation-icon'
import {
  InvestorDashboardIconOutline,
  InvestorDashboardIconSolid,
} from '@components/other/investor-dashboard-icon'
import { Role } from '@constants/role'
import { AuthContextValues } from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import {
  BellAlertIcon,
  ChartBarIcon,
  DocumentPlusIcon,
  KeyIcon,
  PencilSquareIcon,
  Squares2X2Icon as ViewGridIcon,
} from '@heroicons/react/24/outline'
import {
  BellAlertIcon as BellAlertSolid,
  ChartBarIcon as ChartBarSolid,
  DocumentPlusIcon as DocumentPlusIconSolid,
  KeyIcon as SolidKeyIcon,
  PencilSquareIcon as PencilSquareSolid,
  Squares2X2Icon as ViewGridSolid,
} from '@heroicons/react/24/solid'
import { FeatureFlag } from '@interfaces/control'
import { CovenantType } from '@interfaces/manage-monitor-covenant'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import EntitiesTemplatesOverviewL3 from '@pages/administration/setting/accounting-config'
import AccountingOverviewL3 from '@pages/administration/sources/accounting'
import BankOverviewL3 from '@pages/administration/sources/banks'
import UploadsOverviewL3 from '@pages/administration/sources/uploads'
import KeyIndicatorsL3 from '@pages/analytics/financials/key-indicators'
import FinancialOverviewL3 from '@pages/analytics/financials/overview'
import StatementsL3 from '@pages/analytics/financials/statements'
import AnalyticsProCategoriesOverviewL3 from '@pages/analytics/pro/categories'
import CurvesL3 from '@pages/analytics/risk/curves'
import DetailsL3 from '@pages/data-validation/quarantine/details'
import SummaryL3 from '@pages/data-validation/quarantine/summary'
import {
  InvestorBenchmarkL3,
  InvestorCompositionL3,
  InvestorFacilitiesL3,
  InvestorOverviewL3,
} from '@pages/investor'
import PaymentAuditL3 from '@pages/manage/audit/payment'
import UnderwritingAuditL3 from '@pages/manage/audit/underwriting'
import ActionsL3 from '@pages/manage/monitor/actions'
import MonitorBorrowingBaseL3 from '@pages/manage/monitor/borrowing-base'
import CashflowsL3 from '@pages/manage/monitor/cashflows'
import MonitorCovenantL3 from '@pages/manage/monitor/covenant'
import MonitorDashboardL3 from '@pages/manage/monitor/dashboard'
import NotificationLogs from '@pages/notification/notifications'
import FacilityDetailsL3 from '@pages/superadmin/manage-admin/facility-details-l3'
import AdminControlService from '@services/api-admin/control'
import { AnalyticsProService } from '@services/api-analytics/analytics-pro'

import features, { initialFeatureFlagMapping } from './features/features'
export interface RoutesProps {
  /**
   * used as routing path
   */
  path: string
  /**
   * used to check if feature is enabled by default or not. If this property is not provided, path is used instead.
   */
  featureName?: string
  /**
   * used as navigation title
   */
  title: string
  /**
   * used as navigation icon
   */
  icon?: any
  /**
   * used as navigation icon while `path is active
   */
  activeIcon?: any
  /**
   * whether this route hidden or not from side menu
   */
  menuHidden?: boolean
  /**
   * component that will be rendered. Only for latest child
   */
  component?: any
  /**
   * nested routing
   */
  routes?: RoutesProps[]
  /**
   * function to validate whether current user is authorized for menu
   */
  isAuthorized?: (
    role: Role,
    slug?: string,
    context?: AuthContextValues,
    facility_id?: number
  ) => boolean
  /**
   * level order
   */
  order?: number
  /**
   * route access
   */
  access?: string[]
  /**
   * route access specific for external user. if not available fall back to access
   */
  access_external?: string[]
  /**
   * Prefix of permission for a feature that will be send to the back-end for custom users.
   * This string will be appended with the list of string from @access
   */
  permissionPrefix: string
  /**
   * Dynamic routes do not have their own permissions. These components are sharing
   * the permissions from the parent layer. It would be hard to maintain dynamic permissions,
   * since these are generated by databricks team and need to be inserted into cascade-stack DB.
   */
  isDynamicComponent?: boolean
}

const manageModuleFeatureCheck = (
  feature: string,
  context?: AuthContextValues,
  facility_id?: number
) => {
  const location = useLocation()
  const { company, appliedFilters, optionFilters } = context ?? {}
  const params = new URLSearchParams(location.search)

  // allows redirect to a different company from a master facility
  if (company?.slug_name !== params.get('pid')) {
    return true
  }

  const { activeFacilityId } = appliedFilters ?? {}
  const { facilities = [] } = optionFilters ?? {}
  const activeFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === (facility_id ?? activeFacilityId)
  )
  const default_features =
    'overview, dashboard, borrowing-base, portfolio-covenants, financial-covenants, cashflows, actions'

  return (activeFacility?.features ?? default_features).includes(feature)
}

const Routes: RoutesProps[] = [
  {
    path: 'administration',
    permissionPrefix: 'administration',
    title: 'Administration',
    icon: ViewGridIcon,
    activeIcon: ViewGridSolid,
    order: 1,
    routes: [
      {
        path: 'sources',
        permissionPrefix: 'administration_sources',
        title: 'Data Sources',
        isAuthorized: (role: Role) =>
          [Role.superadmin, Role.admin, Role.custom].includes(role),
        routes: [
          {
            path: 'banking',
            title: 'Banking',
            component: <BankOverviewL3 />,
            permissionPrefix: 'administration_sources_banking',
            access: ['access', 'create', 'edit'],
          },
          {
            path: 'accounting',
            title: 'Accounting',
            component: <AccountingOverviewL3 />,
            permissionPrefix: 'administration_sources_accounting',
            access: ['access', 'create', 'edit'],
            isAuthorized: (_r, _s, context) =>
              context?.company?.has_financials ?? false,
          },
          {
            path: 'uploads',
            title: 'Historical Uploads',
            component: <UploadsOverviewL3 />,
            access: ['access', 'create', 'edit'],
            permissionPrefix: 'administration_sources_uploads',
            isAuthorized: (_r, _s, context) =>
              context?.company?.has_financials ?? false,
          },
        ],
      },
      {
        path: 'setting',
        permissionPrefix: 'administration_setting',
        title: 'Settings',
        routes: [
          {
            path: 'accounting-config',
            title: 'Accounting Config',
            component: <EntitiesTemplatesOverviewL3 />,
            permissionPrefix: 'administration_setting_accounting-config',
            access: ['access', 'create', 'edit', 'delete'],
            isAuthorized: (role: Role, _s, context) =>
              [Role.superadmin].includes(role) &&
              (context?.company?.has_financials ?? false),
          },
          {
            path: 'users',
            title: 'Users',
            component: <UsersPage />,
            permissionPrefix: 'administration_setting_users',
            access: ['access', 'create', 'edit', 'delete'],
            isAuthorized: (role: Role) =>
              [Role.superadmin, Role.admin, Role.custom].includes(role),
          },
        ],
      },
    ],
  },
  {
    path: 'analytics',
    permissionPrefix: 'analytics',
    title: 'Analytics',
    icon: ChartBarIcon,
    activeIcon: ChartBarSolid,
    order: 3,
    routes: [
      {
        path: 'risk',
        permissionPrefix: 'analytics_risk',
        title: 'Risk',
        isAuthorized: (_r, _s, context) => context?.company?.has_risk ?? false,
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            permissionPrefix: 'analytics_risk_overview',
            access: ['access'],
            component: <RiskOverviewL3 />,
          },
          {
            path: 'indicators',
            title: 'Key Indicators',
            permissionPrefix: 'analytics_risk_indicators',
            access: ['access'],
            component: <RiskIndicatorsL3 />,
          },
          {
            path: 'traction',
            title: 'Traction',
            permissionPrefix: 'analytics_risk_traction',
            component: <TractionPage />,
            routes: [
              {
                path: 'volume',
                permissionPrefix: 'analytics_risk_traction_volume',
                title: 'Volume',
                routes: [
                  {
                    path: 'monthly',
                    title: 'Monthly',
                    permissionPrefix: 'analytics_risk_traction_volume_monthly',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'total',
                    title: 'Total',
                    permissionPrefix: 'analytics_risk_traction_volume_total',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'clients',
                title: 'Clients',
                permissionPrefix: 'analytics_risk_traction_clients',
                routes: [
                  {
                    path: 'monthly-active-clients',
                    title: 'Monthly Active Clients',
                    permissionPrefix:
                      'analytics_risk_traction_clients_monthly-active-clients',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'total-unique-clients',
                    title: 'Total Unique Clients',
                    permissionPrefix:
                      'analytics_risk_traction_clients_total-unique-clients',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'balance',
                title: 'Balance',
                permissionPrefix: 'analytics_risk_traction_balance',
                routes: [
                  {
                    path: 'balance-volume',
                    title: 'Balance Volume',
                    permissionPrefix:
                      'analytics_risk_traction_balance_balance-volume',
                    access: ['access', 'export'],
                  },
                  {
                    path: '#-of-loans',
                    title: '# of Loans',
                    permissionPrefix:
                      'analytics_risk_traction_balance_#-of-loans',
                    access: ['access', 'export'],
                  },
                ],
              },
            ],
          },
          {
            path: 'delinquency',
            title: 'Delinquency',
            permissionPrefix: 'analytics_risk_delinquency',
            component: <DelinquencyPage />,
            routes: [
              {
                path: 'portfolio-at-risk',
                title: 'Portfolio at Risk',
                permissionPrefix:
                  'analytics_risk_delinquency_portfolio-at-risk',
                access: ['access', 'export'],
              },
              {
                path: 'rolling-default-rate',
                title: 'Rolling Default Rate',
                permissionPrefix:
                  'analytics_risk_delinquency_rolling-default-rate',
                access: ['access', 'export'],
              },
              {
                path: 'outstanding-by-delinquency',
                title: 'Outstanding by Delinquency',
                permissionPrefix:
                  'analytics_risk_delinquency_outstanding-by-delinquency',
                access: ['access', 'export'],
              },
              {
                path: 'first-payment-default(fpd)-by-cohort',
                title: 'First Payment Default(FPD) by Cohort',
                permissionPrefix:
                  'analytics_risk_delinquency_first-payment-default(fpd)-by-cohort',
                access: ['access', 'export'],
              },
              {
                path: 'borrower-concentration',
                title: 'Borrower Concentration',
                permissionPrefix:
                  'analytics_risk_delinquency_borrower-concentration',
                access: ['access', 'export'],
              },
              {
                path: 'delinquency-by-cohort',
                title: 'Delinquency by Cohort',
                permissionPrefix:
                  'analytics_risk_delinquency_delinquency-by-cohort',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'collection',
            title: 'Collection',
            permissionPrefix: 'analytics_risk_collection',
            component: <CollectionPage />,
            routes: [
              {
                path: 'collection-rate',
                title: 'Collection Rate',
                permissionPrefix: 'analytics_risk_collection_collection-rate',
                access: ['access', 'export'],
              },
              {
                path: 'prepayment-rate-by-cohort',
                title: 'Prepayment Rate by Cohort',
                permissionPrefix:
                  'analytics_risk_collection_prepayment-rate-by-cohort',
                access: ['access', 'export'],
              },
              {
                path: 'collection-by-status',
                title: 'Collection by Status',
                permissionPrefix:
                  'analytics_risk_collection_collection-by-status',
                access: ['access', 'export'],
              },
              {
                path: 'net-yield',
                title: 'Net Yield',
                permissionPrefix: 'analytics_risk_collection_net-yield',
                access: ['access', 'export'],
              },
              {
                path: 'aggregated-roll-rates',
                title: 'Aggregated Roll Rates',
                permissionPrefix:
                  'analytics_risk_collection_aggregated-roll-rates',
                access: ['access', 'export'],
              },
              {
                path: 'cash-collected-by-cohort',
                title: 'Cash Collected by Cohort',
                permissionPrefix:
                  'analytics_risk_collection_cash-collected-by-cohort',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'curves',
            title: 'Curves',
            permissionPrefix: 'analytics_risk_curves',
            component: <CurvesL3 />,
            routes: [
              {
                path: 'conditional-default-rate',
                title: 'Conditional Default Rate',
                permissionPrefix:
                  'analytics_risk_curves_conditional-default-rate',
                access: ['access', 'export'],
              },
              {
                path: 'single-month-mortality',
                title: 'Single Month Mortality',
                permissionPrefix:
                  'analytics_risk_curves_single-month-mortality',
                access: ['access', 'export'],
              },
              {
                path: 'conditional-prepayment-rate',
                title: 'Conditional Prepayment Rate',
                permissionPrefix:
                  'analytics_risk_curves_conditional-prepayment-rate',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'cohort',
            title: 'Cohort',
            permissionPrefix: 'analytics_risk_cohort',
            component: <CohortPage />,
            routes: [
              {
                path: 'vintage-analysis',
                title: 'Vintage Analysis',
                permissionPrefix: 'analytics_risk_cohort_vintage-analysis',
                access: ['access', 'export'],
              },
              {
                path: 'roll-rates',
                title: 'Roll Rates',
                permissionPrefix:
                  'analytics_risk_collection_aggregated-roll-rates',
                access: ['access', 'export'],
              },
              {
                path: 'roll-rates-by-cohort',
                title: 'Roll Rates by Cohort',
                permissionPrefix: 'analytics_risk_cohort_roll-rates-by-cohort',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'characteristics',
            title: 'Characteristics',
            permissionPrefix: 'analytics_risk_characteristics',
            component: <CharacteristicPage />,
            routes: [
              {
                path: 'interest-rate',
                title: 'Interest Rate by Cohort',
                permissionPrefix:
                  'analytics_risk_characteristics_interest-rate',
                routes: [
                  {
                    path: 'distribution',
                    title: 'Distribution',
                    permissionPrefix:
                      'analytics_risk_characteristics_interest-rate_distribution',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'average',
                    title: 'Average',
                    permissionPrefix:
                      'analytics_risk_characteristics_interest-rate_average',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'term',
                title: 'Term by Cohort',
                permissionPrefix: 'analytics_risk_characteristics_term',
                routes: [
                  {
                    path: 'distribution',
                    title: 'Distribution',
                    permissionPrefix:
                      'analytics_risk_characteristics_term_distribution',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'average',
                    title: 'Average',
                    permissionPrefix:
                      'analytics_risk_characteristics_term_average',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'value',
                title: 'Value by Cohort',
                permissionPrefix: 'analytics_risk_value',
                routes: [
                  {
                    path: 'distribution',
                    title: 'Distribution',
                    permissionPrefix:
                      'analytics_risk_characteristics_value_distribution',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'average',
                    title: 'Average',
                    permissionPrefix:
                      'analytics_risk_characteristics_value_average',
                    access: ['access', 'export'],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'financials',
        title: 'Financials',
        permissionPrefix: 'analytics_financials',
        isAuthorized: (_r, _s, context) =>
          context?.company?.has_financials ?? false,
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            component: <FinancialOverviewL3 />,
            permissionPrefix: 'analytics_financials_overview',
            access: ['access'],
          },
          {
            path: 'key-indicators',
            title: 'Key Indicators',
            permissionPrefix: 'analytics_financials_key-indicators',
            component: <KeyIndicatorsL3 />,
            routes: [
              {
                path: 'net-income',
                title: 'Net Income',
                permissionPrefix:
                  'analytics_financials_key-indicators_net-income',
                access: ['access', 'export'],
              },
              {
                path: 'cash',
                title: 'Cash',
                permissionPrefix: 'analytics_financials_key-indicators_cash',
                routes: [
                  {
                    path: 'cash',
                    title: 'Cash',
                    permissionPrefix:
                      'analytics_financials_key-indicators_cash_cash',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'bank-account-distribution',
                    title: 'Bank Account Distribution',
                    permissionPrefix:
                      'analytics_financials_key-indicators_cash_bank-account-distribution',
                    access: ['access'],
                  },
                ],
              },
              {
                path: 'net-worth',
                title: 'Net Worth',
                permissionPrefix:
                  'analytics_financials_key-indicators_net-worth',
                access: ['access', 'export'],
              },
              {
                path: 'runway',
                title: 'Runway',
                permissionPrefix: 'analytics_financials_key-indicators_runway',
                access: ['access', 'export'],
              },
              {
                path: 'debt:equity',
                title: 'Debt:Equity',
                permissionPrefix:
                  'analytics_financials_key-indicators_debt:equity',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'statements',
            title: 'Statements',
            permissionPrefix: 'analytics_financials_statements',
            component: <StatementsL3 />,
            routes: [
              {
                path: 'balance-sheet',
                title: 'Balance Sheet',
                permissionPrefix:
                  'analytics_financials_statements_balance-sheet',
                access: ['access'],
              },
              {
                path: 'profit-&-loss',
                title: 'Profit Loss',
                permissionPrefix:
                  'analytics_financials_statements_profit-&-loss',
                access: ['access'],
              },
              {
                path: 'cashflow',
                title: 'Cashflow',
                permissionPrefix: 'analytics_financials_statements_cashflow',
                access: ['access'],
              },
              {
                path: 'custom-sheet',
                title: 'Custom Sheet',
                permissionPrefix:
                  'analytics_financials_statements_custom-sheet',
                access: ['access'],
              },
            ],
          },
        ],
      },

      {
        path: 'data-exports',
        title: 'Data Exports',
        permissionPrefix: 'analytics_data-exports',
        isAuthorized: (_r, _s, context) => context?.company?.has_risk ?? false,
        routes: [
          {
            path: 'loan-tape',
            title: 'Loan Tape',
            permissionPrefix: 'analytics_data-exports_loan-tape',
            access: ['access', 'export'],
            component: <LoantapeOverviewL3 />,
          },
        ],
      },
    ],
  },
  {
    path: 'analytics-pro',
    title: 'Analytics Pro',
    icon: DocumentPlusIcon,
    activeIcon: DocumentPlusIconSolid,
    permissionPrefix: 'analytics-pro',
    order: 4,
    routes: [
      {
        path: 'categories',
        permissionPrefix: 'analytics-pro_categories_charts',
        access: ['access', 'export'],
        title: 'Categories',
        isAuthorized: (_r, _s, context) =>
          context?.company?.has_analytics_pro ?? false,
        routes: [
          {
            path: '',
            title: '',
            component: <AnalyticsProCategoriesOverviewL3 category="" />,
            isDynamicComponent: true,
            permissionPrefix: 'analytics-pro_categories_charts',
            access: ['access'],
          },
        ],
      },
    ],
  },
  {
    path: 'manage',
    title: 'Manage',
    permissionPrefix: 'manage',
    icon: PencilSquareIcon,
    activeIcon: PencilSquareSolid,
    order: 5,
    routes: [
      {
        path: 'monitor',
        permissionPrefix: 'manage_monitor',
        title: 'Monitor',
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            component: <MonitorOverviewL3 />,
            permissionPrefix: 'manage_monitor_overview',
            access: ['access'],
          },
          {
            path: 'dashboard',
            title: 'Dashboard',
            component: <MonitorDashboardL3 />,
            isAuthorized: (_r, _s, context, facility_id) =>
              manageModuleFeatureCheck('dashboard', context, facility_id),
            permissionPrefix: 'manage_monitor_dashboard',
            access: ['access', 'export'],
          },
          {
            path: 'borrowing-base',
            title: 'Borrowing Base',
            permissionPrefix: 'manage_monitor_borrowing-base',
            component: <MonitorBorrowingBaseL3 />,
            isAuthorized: (_r, _s, context, facility_id) =>
              manageModuleFeatureCheck('borrowing-base', context, facility_id),
            routes: [
              {
                path: 'borrowing-base',
                title: 'Borrowing Base',
                permissionPrefix:
                  'manage_monitor_borrowing-base_borrowing-base',
                access: ['access', 'export'],
              },
              {
                path: 'collateral',
                title: 'Collateral',
                permissionPrefix: 'manage_monitor_borrowing-base_collateral',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'portfolio-covenants',
            title: 'Portfolio Covenants',
            component: <MonitorCovenantL3 type={CovenantType.portfolio} />,
            isAuthorized: (_r, _s, context, facility_id) =>
              manageModuleFeatureCheck(
                'portfolio-covenants',
                context,
                facility_id
              ),
            permissionPrefix: 'manage_monitor_portfolio-covenants',
            access: ['access', 'export'],
          },
          {
            path: 'financial-covenants',
            title: 'Financial Covenants',
            component: <MonitorCovenantL3 type={CovenantType.financial} />,
            isAuthorized: (_r, _s, context, facility_id) =>
              manageModuleFeatureCheck(
                'financial-covenants',
                context,
                facility_id
              ),
            permissionPrefix: 'manage_monitor_financial-covenants',
            access: ['access', 'export'],
          },
          {
            path: 'cashflows',
            permissionPrefix: 'manage_monitor_cashflows',
            title: 'Cashflows',
            component: <CashflowsL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('cashflows', context),
            routes: [
              {
                path: 'payment-schedule',
                title: 'Payment Schedule',
                permissionPrefix: 'manage_monitor_cashflows_payment-schedule',
                access: ['access'],
              },
            ],
          },
          {
            path: 'actions',
            title: 'Actions',
            component: <ActionsL3 />,
            permissionPrefix: 'manage_monitor_actions',
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('actions', context),
            routes: [
              {
                path: 'document-centre',
                title: 'Document Center',
                permissionPrefix: 'manage_monitor_actions_document-centre',
                access: ['access', 'create', 'sign'],
              },
              {
                path: 'waterfall',
                title: 'Waterfall',
                permissionPrefix: 'manage_monitor_actions_waterfall',
                access: ['access', 'create', 'sign'],
              },
              {
                path: 'advance-request',
                title: 'Advance Request',
                permissionPrefix: 'manage_monitor_actions_advance-request',
                access: ['access', 'create', 'sign'],
              },
            ],
          },
        ],
      },
      {
        path: 'audit',
        permissionPrefix: 'manage_audit',
        title: 'Audit',
        isAuthorized: (_r, _s, context) =>
          manageModuleFeatureCheck('-audit', context),
        routes: [
          {
            path: 'payment-audit',
            title: 'Payment Audit',
            component: <PaymentAuditL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('payment-audit', context),
            permissionPrefix: 'manage_audit_payment-audit',
            access: ['access', 'upload', 'approve/reject', 'export'],
          },
          {
            path: 'underwriting-audit',
            title: 'Underwriting Audit',
            component: <UnderwritingAuditL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('underwriting-audit', context),
            permissionPrefix: 'manage_audit_underwriting-audit',
            access: ['access', 'upload', 'approve/reject', 'export'],
          },
        ],
      },
    ],
  },
  {
    path: 'investor',
    permissionPrefix: 'investor',
    title: 'Investor Dashboard',
    icon: InvestorDashboardIconOutline,
    activeIcon: InvestorDashboardIconSolid,
    order: 2,
    routes: [
      {
        path: 'dashboard',
        permissionPrefix: 'investor_dashboard',
        title: 'Overview',
        isAuthorized: (role: Role) =>
          [Role.superadmin, Role.admin, Role.custom, Role.staff].includes(role),
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            permissionPrefix: 'investor_dashboard_overview',
            access: ['access'],
            component: <InvestorOverviewL3 />,
          },
          {
            path: 'facilities',
            title: 'Facilities',
            component: <InvestorFacilitiesL3 />,
            permissionPrefix: 'investor_dashboard_facilities',
            access: ['access', 'export'],
          },
          {
            path: 'benchmark',
            title: 'Benchmark',
            component: <InvestorBenchmarkL3 />,
            permissionPrefix: 'investor_dashboard_benchmark',
            access: ['access', 'export'],
          },
          {
            path: 'composition',
            title: 'Composition',
            component: <InvestorCompositionL3 />,
            permissionPrefix: 'investor_dashboard_composition',
            access: ['access', 'export'],
          },
        ],
      },
    ],
  },
  {
    path: 'data-validation',
    permissionPrefix: 'data-validation',
    title: 'Data Validation',
    icon: DataValidationIconOutline,
    activeIcon: DataValidationIconSolid, //
    order: 6,
    routes: [
      {
        path: 'quarantine',
        permissionPrefix: 'data-validation_quarantine',
        title: 'Quarantine',
        isAuthorized: (role: Role) =>
          [Role.superadmin, Role.admin, Role.custom, Role.staff].includes(role),
        routes: [
          {
            path: 'summary',
            title: 'Summary Data',
            component: <SummaryL3 />,
            permissionPrefix: 'data-validation_quarantine_summary',
            access: ['access', 'export'],
          },
          {
            path: 'details',
            title: 'Details',
            component: <DetailsL3 />,
            permissionPrefix: 'data-validation_quarantine_details',
            access: ['access', 'export'],
          },
        ],
      },
    ],
  },
  {
    path: 'superadmin',
    title: 'Superadmin',
    permissionPrefix: '',
    icon: KeyIcon,
    activeIcon: SolidKeyIcon,
    order: 7,
    isAuthorized: (role: Role) => role === Role.superadmin,
    routes: [
      {
        path: 'manage-admin',
        title: 'Manage Admin',
        permissionPrefix: '',
        routes: [
          {
            path: 'facility-details',
            title: 'Facility Details',
            component: <FacilityDetailsL3 />,
            permissionPrefix: '',
          },
        ],
      },
    ],
  },
  {
    path: 'notification',
    permissionPrefix: 'notification',
    title: 'Notification Center',
    icon: BellAlertIcon,
    activeIcon: BellAlertSolid,
    order: 8,
    routes: [
      {
        path: 'notifications',
        permissionPrefix: 'notification_notifications',
        title: 'Notifications',
        isAuthorized: (role: Role) =>
          [Role.superadmin, Role.admin, Role.custom, Role.staff].includes(role),
        routes: [
          {
            path: 'logs',
            title: 'Logs',
            menuHidden: false,
            component: <NotificationLogs />,
            permissionPrefix: 'notification_notifications_logs',
            access: ['access', 'create', 'edit'],
          },
        ],
      },
    ],
  },
]

/**
 *
 * @param   {string[]}      auth0Features   Auth0 user's features
 * @returns {string[]}                      Available features
 */
export const AvailableFeatures = (auth0Features?: string[]) => {
  const availableFeatures: string[] =
    auth0Features && auth0Features?.length > 0 ? auth0Features : features

  return availableFeatures
}

const check_accessibility = (
  context: AuthContextValues,
  permissionPrefix: string,
  featureFlagMapping: FeatureFlag[],
  facility_id?: number,
  isAuthorized?: (
    role: Role,
    slug?: string,
    context?: AuthContextValues,
    facility_id?: number
  ) => boolean
) => {
  const { userMetadata, company } = context
  const isSuperadmin = userMetadata?.isSuperadmin
  const userAccess = userMetadata?.companies?.[company?.slug_name ?? '']
  const defaulfRole = Role.staff
  const activeRole = isSuperadmin
    ? Role.superadmin
    : userAccess?.control ?? defaulfRole
  const activeAccess = userAccess?.access ?? {}

  const hasAuthority = isAuthorized
    ? isAuthorized(activeRole, company?.slug_name, context, facility_id)
    : true

  /**
   * Checks if a corresponding feature flag exists and is available.
   * defaults to true when a corresponding feature flag is not explicitly
   * available.
   * If a path has no corresponding feature flag with it is treated as a
   * default path and is turned on
   */
  const pathFeatureFlagEnabled =
    featureFlagMapping.find(
      (f: FeatureFlag) => f.permissionPrefix == permissionPrefix
    )?.control ?? true

  const isAvailableFeature =
    !!AvailableFeatures(Object.keys(activeAccess)).find(x =>
      x.includes(permissionPrefix)
    ) && pathFeatureFlagEnabled

  const isAvailableAccess =
    activeRole === Role.custom
      ? Object.keys(activeAccess).filter(
          aa =>
            aa.includes(permissionPrefix) && activeAccess[aa].includes('access')
        ).length > 0
      : true

  const isAvailable = hasAuthority && isAvailableFeature && isAvailableAccess
  return isAvailable
}

export const useFlaggedRoutes = (
  context: AuthContextValues,
  sort = false
): ((facility_id?: number) => RoutesProps[]) => {
  const { company } = context
  const [featureFlagMapping, setFeatureFlagMapping] = useState(
    initialFeatureFlagMapping
  )

  /**
   * Fetch feature flags and update the control values on success
   * * * * *
   * Maintain with all other module's control services
   * to obtain feature flag info
   * * * * *
   * admin
   */
  const { data: featureFlagData } = useQuery(
    ['allFeatureFlags', company],
    () => {
      const slugName = company?.slug_name
      if (slugName) {
        return AdminControlService.getFeatureFlags(slugName)
      }
    },
    getStaleMins()
  )

  useEffect(() => {
    if (featureFlagData) {
      updateFeatureFlagControl(featureFlagData)
    }
  }, [featureFlagData])

  const updateFeatureFlagControl = (data: any) => {
    const updatedMapping = initialFeatureFlagMapping.map(
      (feature: FeatureFlag) => ({
        ...feature,
        control: data?.data[feature.flag.toLowerCase()] ?? feature.control,
      })
    )
    setFeatureFlagMapping(updatedMapping)
  }

  const { data: loadedAnalyticsProCategories, isFetched: analyticsProFetched } =
    useQuery(
      ['analyticsProCategories', company?.slug_name],
      () => {
        const slugName = company?.slug_name
        if (slugName) {
          return AnalyticsProService.listCategories(slugName)
        }
      },
      {
        staleTime: 30 * 1000 * 60,
        enabled:
          (company?.has_analytics_pro ?? false) &&
          (featureFlagMapping.find(f => f.path === 'analytics-pro')?.control ??
            false),
      }
    )

  useEffect(() => {
    if (analyticsProFetched) {
      updateAnalyticsProCategories(loadedAnalyticsProCategories)
    }
  }, [company?.slug_name, analyticsProFetched])

  const updateAnalyticsProCategories = (data: any) => {
    const routes = Routes.find(r => r.path === 'analytics-pro')?.routes?.[0]
    if (!routes) {
      return
    }
    const categoryRoutes: RoutesProps[] = []
    data?.map((category: string) => {
      categoryRoutes.push({
        path: category.replaceAll(' ', '-').toLowerCase(),
        title: category,
        component: <AnalyticsProCategoriesOverviewL3 category={category} />,
        isDynamicComponent: true,
        permissionPrefix: 'analytics-pro_categories_charts',
        access: ['access'],
      })
    })
    routes.routes = categoryRoutes
  }

  /**
   * we're currently assuming every company has access to administration, analytic basic module by default
   */
  const modules: { [key: string]: boolean } = {
    administration: true,
    analytics: company?.has_analytics ?? true,
    ['analytics-pro']:
      (company?.has_analytics_pro ?? false) &&
      (featureFlagMapping.find(f => f.path === 'analytics-pro')?.control ??
        false),
    manage: company?.has_manage ?? false,
    raise: company?.has_raise ?? false,
    superadmin: true,
    ['data-validation']: true, // TODO need to be added after "has_DV"
    ['notification']: true, // TODO need to be added after "has_notification"??
    ['investor']: true, // TODO need to be added after "has_investor"
  }

  const getFlaggedRoutes = (facility_id?: number): RoutesProps[] => {
    const routing = Routes.filter(l1 => {
      const permissionPrefix = l1.permissionPrefix
      const is_available = check_accessibility(
        context,
        permissionPrefix,
        featureFlagMapping,
        facility_id,
        l1.isAuthorized
      )
      return is_available && modules?.[l1.path]
    })
      .map(l1 => {
        const l2 = l1.routes
          ?.filter(l2 => {
            const permissionPrefix = l2.permissionPrefix
            const is_available = check_accessibility(
              context,
              permissionPrefix,
              featureFlagMapping,
              facility_id,
              l2.isAuthorized
            )
            return is_available
          })
          .map(l2 => {
            const l3 = l2.routes?.filter(l3 => {
              const permissionPrefix = l3.permissionPrefix
              const is_available = check_accessibility(
                context,
                permissionPrefix,
                featureFlagMapping,
                facility_id,
                l3.isAuthorized
              )
              return is_available
            })
            return {
              ...l2,
              routes: l3,
            }
          })
          .filter(f => f.routes && f.routes.length > 0)

        return {
          ...l1,
          routes: l2,
        }
      })
      .filter(f => f.routes && f.routes.length > 0)
    return sort
      ? routing.sort((a, b) => ((a?.order ?? 0) < (b?.order ?? 0) ? -1 : 1))
      : routing
  }
  return getFlaggedRoutes
}
