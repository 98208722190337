/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import Typography from '@components/atoms/typography'
import { redGreenHeatmap } from '@helpers/heatmap-palettes'
import {
  ArrowPathIcon,
  ArrowsUpDownIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'

import { ExpandableProps, Part, SortOrder, TableProps } from './type'

const getBackgroundColour = (
  invertColours: boolean,
  value: number,
  maxValue: number
) => {
  const valRange =
    maxValue > 0
      ? Math.floor(
          ((invertColours ? maxValue - value : value) / maxValue) * 100
        )
      : 0
  return redGreenHeatmap[Math.min(valRange, 100)]
}

/**
 * This is a custom table that we use for the majority of tables on Cascade.
 * @param param0
 * @returns
 */
const CustomTable = ({
  containerClass,
  title,
  allowOverflow = true,
  columns,
  data = [],
  headerData,
  footerData,
  loading = false,
  expandable,
  sortField,
  sortOrder,
  borderless = false,
  clickable = true,
  fitInView = false, // Often used together without allowOverflow*
  stickyLeftCount = 0,
  maxVal = 0,
  includeHeatMap = false,
  invertColours = false,
  onSort,
  onRowClick,
  rowClass,
}: TableProps) => {
  const {
    expandedIndexes = [],
    onExpand,
    expandedRowRender,
  } = expandable ?? ({} as ExpandableProps)

  const _renderColumnData = (record: any, recordIndex: number, part: Part) => {
    let stickyCells = stickyLeftCount
    return columns.map(
      ({ head, title, field, render, props, ...restColumn }, j) => {
        const allProps = {
          ...restColumn,
          ...props?.(record, recordIndex, part),
        }
        return (
          <td
            key={j}
            {...allProps}
            {...(stickyCells-- > 0 && {
              className: `sticky left-0 bg-neutral-white`,
            })}
            {...(includeHeatMap &&
              stickyCells <= 0 && {
                style: {
                  backgroundColor: getBackgroundColour(
                    invertColours,
                    record[field],
                    maxVal
                  ),
                },
              })}
          >
            {record[field]?.content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: record[field]?.content,
                }}
              />
            )}

            {record[field]?.table && (
              <div className="mt-4">
                <CustomTable
                  columns={record[field].table.columns}
                  data={record[field].table.data}
                />
              </div>
            )}

            {!record[field]?.content &&
              !record[field]?.table &&
              render &&
              render(record, recordIndex, part)}

            {!record[field]?.content && !record[field]?.table && !render && (
              <div
                dangerouslySetInnerHTML={{
                  __html: field.includes('.')
                    ? (() => {
                        const [beforeDot, afterDot] = field.split('.')
                        return record[beforeDot]?.[afterDot] || ''
                      })()
                    : record[field],
                }}
              />
            )}
          </td>
        )
      }
    )
  }

  const useTableCSS = () => {
    let css = `cascade-table ${containerClass}`
    allowOverflow && (css += ' overflow-auto')
    fitInView && (css += ' max-h-[calc(100vh-10rem)]')
    return css
  }

  const getIcon = (field: string, sortOrder?: SortOrder) => {
    if (sortField === field) {
      return sortOrder === 'asc' ? BarsArrowUpIcon : BarsArrowDownIcon
    }
    return ArrowsUpDownIcon
  }

  return (
    <div className="flex flex-col flex-1">
      {title && (
        <Typography className="text-xl text-neutral-black font-medium mb-4">
          {title}
        </Typography>
      )}
      {/* DO NOT DELETE — this is a layer around table to clip off the scroll bar for rounded edge */}
      <div className="rounded-lg overflow-hidden">
        <div className={useTableCSS()}>
          <table className="w-full h-full">
            <thead className="sticky top-0 z-20 ">
              <tr>
                {!!expandable && (
                  <th className={`${borderless && 'border-none'}`}></th>
                )}
                {columns.map(
                  (
                    {
                      head,
                      title,
                      field,
                      headRender,
                      sortable = false,
                      render,
                      ...restColumn
                    },
                    i
                  ) => {
                    const props = { ...restColumn, ...head }
                    const Icon = getIcon(field, sortOrder)
                    return props.colSpan === 0 ? null : (
                      <th
                        className="fixed sticky"
                        key={i}
                        {...props}
                        onClick={() => {
                          const order =
                            sortField === field && sortOrder === 'asc'
                              ? 'desc'
                              : sortField === field && sortOrder === 'desc'
                              ? ''
                              : 'asc'
                          sortable && onSort?.(field, order)
                        }}
                      >
                        {sortable && (
                          <Icon
                            className={`w-4 h-4 mr-4   ${
                              sortField === field ? 'text-primary-main' : ''
                            }`}
                          />
                        )}
                        <div className="text-cc-primary-gray-dark/60 font-medium">
                          {headRender?.() ?? title}
                        </div>
                      </th>
                    )
                  }
                )}
              </tr>
              {headerData?.map((d, i) => {
                return (
                  <React.Fragment key={i}>
                    <tr>
                      {!!expandable && <th></th>}
                      {_renderColumnData(d, i, 'head')}
                    </tr>
                  </React.Fragment>
                )
              })}
            </thead>

            <tbody className={`${clickable && 'cursor-pointer'}`}>
              {loading && (
                <tr>
                  <td
                    colSpan={columns.length + (expandable ? 1 : 0)}
                    align="center"
                  >
                    <ArrowPathIcon className="w-6 h-6 text-primary-main animate-spin m-6" />
                  </td>
                </tr>
              )}
              {!loading &&
                data?.length > 0 &&
                data?.map((d, i) => {
                  const isOpen = expandedIndexes.includes(i)
                  const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon
                  return (
                    <React.Fragment key={i}>
                      <tr
                        onClick={() => {
                          !expandable && !!onRowClick && onRowClick?.(d, i)
                        }}
                        className={`${rowClass?.(d, i)} row-head  `}
                      >
                        {!!expandable && (
                          <td
                            className={`${borderless && 'border-none'}  `}
                            width="50px"
                            align="center"
                            onClick={() => {
                              !!expandable && onExpand?.(i)
                            }}
                          >
                            <Icon
                              className={`w-4 h-4 ${
                                isOpen ? 'icon-opened' : 'icon-closed'
                              }  `}
                            />
                          </td>
                        )}
                        {_renderColumnData(d, i, 'body')}
                      </tr>
                      {!!expandable && isOpen && (
                        <tr
                          className={`${rowClass?.(
                            d,
                            i
                          )} row-expandable  cursor-`}
                        >
                          <td
                            colSpan={columns.length + 1}
                            className={`${borderless && 'border-none'} `}
                          >
                            {expandedRowRender?.(d, i)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })}
              {!loading && data.length === 0 && (
                <tr>
                  <td
                    colSpan={columns.length + (expandable ? 1 : 0)}
                    className="text-center"
                  >
                    {`No ${title || ''} data`}
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              {footerData?.map((d, i) => {
                return (
                  <React.Fragment key={i}>
                    <tr>
                      {!!expandable && <th></th>}
                      {_renderColumnData(d, i, 'foot')}
                    </tr>
                  </React.Fragment>
                )
              })}
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CustomTable
