import React from 'react'

export const InvestorDashboardIconOutline = React.forwardRef<
  HTMLImageElement,
  React.ImgHTMLAttributes<HTMLImageElement>
>((props, ref) => (
  <img
    {...props}
    ref={ref}
    src="/icons/investor-dashboard_outline.svg"
    alt="Investor Dashboard Icon"
    className="w-6 h-6"
  />
))

export const InvestorDashboardIconSolid = React.forwardRef<
  HTMLImageElement,
  React.ImgHTMLAttributes<HTMLImageElement>
>((props, ref) => (
  <img
    {...props}
    ref={ref}
    src="/icons/investor-dashboard_solid.svg"
    alt="Investor Dashboard Icon"
    className="w-6 h-6"
  />
))

InvestorDashboardIconOutline.displayName = 'InvestorDashboardIconOutline'
InvestorDashboardIconSolid.displayName = 'InvestorDashboardIconSolid'
